/* eslint-disable @typescript-eslint/no-empty-function */
import {
  getAllFailed,
  getAllSuccess,
} from 'data/store/reducer/roomLite/actions/getAll';
import { iActionGetAll } from 'domain/interfaces/redux/roomLite/getAll';
import { ListRoomLite } from 'domain/usecases/roomLite/remote/ListRoomLite';
import { makeRemoteListRoomLite } from 'main/factories/usecases/roomLite/GetAllRoomLiteFactory';
import { call, put } from 'redux-saga/effects';

export function* onGetAll(action: iActionGetAll) {
  const remoteListRoomLite = makeRemoteListRoomLite();

  try {
    const response: ListRoomLite.Model = yield call(
      remoteListRoomLite.list,
      action.payload,
    );
    yield put(
      getAllSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(getAllFailed());
  }
}

export function onGetAllSuccess(): void {}

export function onGetAllFailed(): void {}
