/* eslint-disable react/react-in-jsx-scope */
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { CreateRoomLite } from 'domain/usecases/roomLite/remote';
import { makeReduxCreateRoomLite } from 'main/factories/usecases/roomLite/CreateRoomLiteFactory';
import { IconBallonChat, IconClose } from 'presentation/base/icons';
import { closeModal } from 'utils/modalFunctions';
import { schemaCreateRoomLite } from 'validation/validators/CreateRoom/CreateRoomValidator';
import RadioComponent from '../../Radio';
import TagArea from '../../TagArea';
import { Button, Input } from '../../UI';
import {
  Body,
  CloseButtonModal,
  Content,
  Header,
  IconTitle,
  ModalContainer,
  Title,
} from '../styles';
import { AccessInputContainer, Footer } from './styles';
import { CreateRoomModalProps } from './types';

const CreateRoomModal: React.FC<CreateRoomModalProps> = ({ message }) => {
  const [tags, setTags] = useState<string[]>([]);

  const { active: actualModalActive } = message;
  const modalIsActive =
    actualModalActive === MessageOptions.createRoomLiteModal;

  const formHook = useForm<CreateRoomLite.Params>({
    resolver: schemaCreateRoomLite,
    shouldFocusError: true,
    reValidateMode: 'onSubmit',
    defaultValues: {
      keywords: [],
      joinPolicy: 'PUBLIC',
    },
  });

  const {
    register,
    getValues,
    setValue,
    watch,
    trigger,
    clearErrors,
    reset,
    handleSubmit: handleSubmitForm,
    formState: { errors },
  } = formHook;

  const { loading } = useSelector((store: iStore) => store.roomLite);

  const access = watch('joinPolicy', 'PUBLIC');

  const setJoinPolicy = useCallback(
    (value: 'PUBLIC' | 'RESTRICT') => {
      setValue('joinPolicy', value);
      clearErrors('password');
    },
    [clearErrors, setValue],
  );

  const handleAddAndRemoveTag = useCallback(
    (newTags: string[]) => {
      setTags(newTags);
      setValue('keywords', newTags);
    },
    [setValue],
  );

  const handleSubmit = useCallback(() => {
    const createRoomLite = makeReduxCreateRoomLite();
    trigger().then(isValid => {
      if (isValid) {
        createRoomLite.create({
          title: getValues('title'),
          ...(access === 'RESTRICT' && { password: getValues('password') }),
          keywords: getValues('keywords'),
          joinPolicy: getValues('joinPolicy'),
        });
        reset();
      }
    });
  }, [access, getValues, reset, trigger]);

  const handleCloseModal = useCallback(() => {
    reset();
    closeModal();
  }, [reset]);

  return modalIsActive ? (
    <ModalContainer
      onKeyDown={e => {
        if (e.key === 'Escape') {
          handleCloseModal();
        }
      }}
    >
      <FormProvider {...formHook}>
        <Content onSubmit={handleSubmitForm(handleSubmit)}>
          <Header>
            <Title>
              <IconTitle>
                <IconBallonChat />
              </IconTitle>
              <span>Criar uma sala</span>
            </Title>
            <CloseButtonModal type="button" onClick={handleCloseModal}>
              <IconClose />
            </CloseButtonModal>
          </Header>
          <Body>
            <Input
              {...register('title')}
              autoFocus
              error={!!errors.title}
              message={errors.title?.message}
              color="black4"
              label="Título da sala"
              required
            />
            <RadioComponent
              checked
              label="Acesso"
              required
              options={[
                {
                  value: 'PUBLIC',
                  label: 'Público',
                },
                {
                  value: 'RESTRICT',
                  label: 'Privado',
                },
              ]}
              value={access}
              onClick={setJoinPolicy}
            />

            <AccessInputContainer access={access}>
              <Input
                {...register('password')}
                error={!!errors.password}
                message={errors.password?.message}
                type="password"
                color="black4"
                label="Senha de acesso"
                required
              />
            </AccessInputContainer>
            <TagArea
              label="Palavras-chave"
              tags={tags}
              placeholder="Separe palavras-chave por ponto e vírgula."
              setTags={handleAddAndRemoveTag}
            />
          </Body>
          <Footer>
            <Button
              size="dynamic"
              style={{ width: '100%' }}
              className="btn-create-room create"
              title="Criar sala"
              type="submit"
              variant="primary"
              isLoading={loading}
              onClick={handleSubmit}
            />
            <Button
              size="dynamic"
              style={{ width: '100%' }}
              className="btn-create-room cancel"
              title="Cancelar"
              type="button"
              variant="secondary"
              onClick={handleCloseModal}
            />
          </Footer>
        </Content>
      </FormProvider>
    </ModalContainer>
  ) : null;
};

export default CreateRoomModal;
