/* eslint-disable react/react-in-jsx-scope */
import { iStore, iWrtcClient } from 'domain/interfaces/models';
import { makeReduxUpdateWRTCInfo } from 'main/factories/usecases/auth/UpdateWRTCInfoFactory';
import { makeReduxUpdateWrtc } from 'main/factories/usecases/wrtcClient/UpdateFactory';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { WRTCClient } from 'services/wrtccli/netfans-wrtc-wrapper.js';
import { convertToWRTCRoomName, getSpotIndexFromWRTCRoomName } from 'utils';
import { MessageProps } from '../components/Chat/interfaces';
import { SpotsInfo, iAnimation, iMutedUsers } from '../pages/Room/interface';
import { useChatMessage } from './MessagesPublicChatContext';

type Floor = {
  name: string;
  size: {
    x: number;
    z: number;
  };
};

type WRTCContextProps = {
  connect: () => void;
  disconnectFromRoom: () => void;
  wrtcIsConnected: boolean;
  loadFloors: () => void;
  joinRoom: (eventId: string, roomName: string, redirect?: boolean) => void;
  sendMessage: (data: any) => void;
  floors: Floor[];
  speakingPeerIds: any;
  activeUsers: Array<SpotsInfo>;
  hasMutedUsers: iMutedUsers;
  triggerAnimation: iAnimation;
  setTriggerAnimation: (animation: iAnimation) => void;
  isMicMuted: boolean;
  muteMic: () => void;
  sendChatMessageWrtc: (data: any) => void;
};

const WRTCContext = createContext<WRTCContextProps>({} as WRTCContextProps);

const WRTCProvider: React.FC = ({ children }) => {
  const [floors, setFloors] = useState<Floor[]>([]);
  const [speakingPeerIds, setSpeakingPeerIds] = useState<any>({});
  const [activeUsers, setActiveUsers] = useState<Array<SpotsInfo>>([]);
  const [isMicMuted, setIsMicMuted] = useState(false);
  const [hasMutedUsers, setHasMutedUsers] = useState<iMutedUsers>(
    {} as iMutedUsers,
  );

  const {
    receiveMessage,
    handleAddNewMessage,
    chat,
    triggerAnimation,
    setTriggerAnimation,
  } = useChatMessage();

  const history = useHistory();
  const location = useLocation();

  const wrtc = useSelector((store: iStore) => store.wrtc);
  const { wrtcInfo, user } = useSelector((store: iStore) => store.auth);

  const wrtcIsConnected = useMemo(
    () => Boolean(wrtcInfo?.peerId && wrtcInfo?.peerId !== -1 && user?.email),
    [user, wrtcInfo],
  );

  const onRequestInsideRoomPeersInfosResponse = (updatedSpotsInfo: any) => {
    setActiveUsers(updatedSpotsInfo);
  };

  const onInsideRoomPeerMuteStateChanged = (
    newMuteState: boolean,
    peerId: number,
  ) => {
    setHasMutedUsers((prevState: iMutedUsers) => ({
      ...prevState,
      [peerId]: newMuteState,
    }));
  };

  const attachSinkId = (element: any, deviceId: string) => {
    if (typeof element.sinkId !== 'undefined') {
      element
        .setSinkId(deviceId)
        .then(() => {
          // nop
        })
        .catch((error: any) => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
        });
    } else if (element) {
      console.warn('Browser does not support output device selection.');
    }
  };

  const onRemoteStreamAvailable = useCallback((stream: any, edgeId: number) => {
    let audio: any;
    const container = document.getElementById('audioContainer');
    let device: any;
    let deviceJsonStr: string | null;

    if (container != null) {
      audio = document.getElementById(`edge_${edgeId}`);

      if (audio == null) {
        audio = document.createElement('audio');
        audio.controls = false;
        audio.muted = false;
        audio.autoplay = true;
        audio.volume = 1.0; // 0.0 ~ 1.0 TODO: set volume from query parameter
        audio.id = `edge_${edgeId}`;
        container.appendChild(audio);
      }
      audio.srcObject = stream;
      deviceJsonStr = localStorage.getItem('@netfans/outputDevice');
      if (deviceJsonStr) {
        try {
          device = JSON.parse(deviceJsonStr);
        } catch (e) {
          console.warn('Unable to parse device id from local storage');
        }
        if (typeof device?.value !== 'undefined') {
          attachSinkId(audio, device.value);
        }
      }
    }
  }, []);

  const onRemoteStreamRemoved = (edgeId: number) => {
    const container = document.getElementById('audioContainer');
    if (container != null) {
      const audio = document.getElementById(`edge_${edgeId}`);
      if (audio != null) {
        container.removeChild(audio);
      }
    }
  };

  const onMixedAudioStreamAvailable = (stream: any) => {
    let audio: any;
    const container = document.getElementById('audioContainer');

    if (container != null) {
      audio = document.getElementById(`mixed_audio`);
      if (audio == null) {
        audio = document.createElement('audio');
        audio.controls = false;
        audio.muted = false;
        audio.autoplay = true;
        audio.volume = 1.0; // 0.0 ~ 1.0 TODO: set volume from query parameter
        audio.id = `mixed_audio`;

        container.appendChild(audio);
      }
      audio.srcObject = stream;
    }
  };

  const onMixedAudioStreamRemoved = () => {
    const container = document.getElementById('audioContainer');
    if (container != null) {
      const audio = document.getElementById(`mixed_audio`);
      if (audio != null) {
        container.removeChild(audio);
      }
    }
  };

  const sendChatMessageByWRTC = useCallback(
    (data: any) => {
      if (wrtc?.sendChatMessage) {
        if (data.chat?.isPublic) {
          wrtc?.sendChatMessage(data, -1);
        } else {
          wrtc?.sendChatMessage(data, data.chat?.to?.id);
        }
      }
    },
    [wrtc],
  );

  const sendMessage = useCallback(
    (messageToSend: string) => {
      const hour = new Date().getHours();
      const minute = new Date().getMinutes();

      if (messageToSend !== '' && chat) {
        const message: MessageProps = {
          chat: {
            ...chat,
            id: -1,
            to: {
              ...chat?.to,
              name: String(user.fullName),
            },
            messages: [],
          },
          message: messageToSend,
          type: 'sent',
          timestamp: `${hour < 10 ? `0${hour}` : hour}:${
            minute < 10 ? `0${minute}` : minute
          }`,
          userId: chat?.from?.id ?? 0,
          messageType: 'chat',
        };

        sendChatMessageByWRTC({
          ...message,
          type: 'received',
        });
        handleAddNewMessage(message);
      }
    },
    [chat, handleAddNewMessage, sendChatMessageByWRTC, user.fullName],
  );

  const onAudioStreamLevelChanged = (newLevel: number, peer: number) => {
    // console.debug(`Peer[${peer}] new audio level: `, newLevel);
    // Threshold para ativar indicação visual
    if (newLevel > 0.1) {
      if (setSpeakingPeerIds)
        setSpeakingPeerIds((prevState: any) => ({
          ...prevState,
          [peer]: true,
        }));
    } else if (setSpeakingPeerIds)
      setSpeakingPeerIds((prevState: any) => ({
        ...prevState,
        [peer]: false,
      }));
  };

  const onInsideRoomPeersSpotChanged = useCallback(
    (j: number, i: number, pId: number, pName: string) => {
      setActiveUsers(prev => {
        const formattedArray = [];

        for (let k = 0; k < prev.length; k += 1) {
          if (prev[k].spotCoordI !== i || prev[k].spotCoordJ !== j) {
            if (prev[k].peerId !== pId) {
              const obj = {
                peerId: prev[k].peerId,
                peerName: prev[k].peerName,
                roomName: '',
                spotCoordI: prev[k].spotCoordI,
                spotCoordJ: prev[k].spotCoordJ,
                muted: prev[k].muted,
              };
              formattedArray.push(obj);
            }
          }
        }

        if (pId !== -1) {
          formattedArray.push({
            peerId: pId,
            peerName: pName,
            roomName: '',
            spotCoordI: i,
            spotCoordJ: j,
            muted: hasMutedUsers[pId] ?? false,
          });
        }

        return formattedArray;
      });
    },
    [hasMutedUsers],
  );

  /**
   * Responsible for the connection with the WRTC
   */
  const connect = useCallback(() => {
    if (!wrtc.connect) {
      const client: iWrtcClient = new WRTCClient(window.config.clientConfig);

      client.onremotestreamavailable = onRemoteStreamAvailable;
      client.onremotestreamremoved = onRemoteStreamRemoved;
      client.onmixedaudiostreamavailable = onMixedAudioStreamAvailable;
      client.onmixedaudiostreamremoved = onMixedAudioStreamRemoved;
      client.oninsideroompeermutestatechanged =
        onInsideRoomPeerMuteStateChanged;
      client.onaudiostreamlevelchanged = onAudioStreamLevelChanged;
      client.onrequestinsideroompeersinfosresponse =
        onRequestInsideRoomPeersInfosResponse;
      client.onchatmessage = receiveMessage;
      client.oninsideroompeersspotchanged = onInsideRoomPeersSpotChanged;

      const successOnLogin = () => {
        const peerId = client.getLocalPeersId?.();
        if (peerId) makeReduxUpdateWRTCInfo().update({ peerId });
      };

      const failedOnLogin = (cause?: { msg: string; code: number }) => {
        toast(cause?.msg, { type: 'error' });
        client.disconnect?.();
      };

      client.onconnect = (name: string) => {
        client.loginPeer?.(name, successOnLogin, failedOnLogin);
      };

      if (!wrtcIsConnected) {
        makeReduxUpdateWrtc().update(client);
        try {
          if (user.firstName && user.lastName)
            client.connect?.(`${user.firstName} ${user.lastName}:${user.id}`);
        } catch (e) {
          toast(String(e), { type: 'success' });
        }
      } else {
        makeReduxUpdateWrtc().update(client);
      }
    }

    if (wrtc.connect && !wrtcIsConnected) {
      try {
        if (user.firstName && user.lastName)
          wrtc.connect(`${user.firstName} ${user.lastName}:${user.id}`);
      } catch (e) {
        if (String(e) === 'Already connected') return;

        toast(String(e), { type: 'error' });
      }
    }
  }, [
    onInsideRoomPeersSpotChanged,
    onRemoteStreamAvailable,
    receiveMessage,
    user,
    wrtc,
    wrtcIsConnected,
  ]);

  const disconnectFromRoom = useCallback(() => {
    if (wrtc.leaveFloor && wrtc.leaveRoom) {
      makeReduxUpdateWRTCInfo().update({ insideRoom: false });
      wrtc.leaveFloor();
      wrtc.leaveRoom();
    }
  }, [wrtc]);

  const loadFloors = useCallback(() => {
    const onRequestAllFloorsSuccess = (data: {
      floors: Floor[];
      universeIndex: number;
    }) => {
      const { floors: floorsResponse } = data;
      setFloors(floorsResponse);
    };

    if (wrtc.requestAllFloorsInfos) {
      console.log('requesting floors');
      wrtc.requestAllFloorsInfos(0, onRequestAllFloorsSuccess);
    }
  }, [wrtc]);

  const createFloor = useCallback(
    (eventId: string) => {
      if (wrtc.createFloor) {
        wrtc.createFloor(`floor_${eventId}`, 0, 10, 15);
        loadFloors();
      }
    },
    [loadFloors, wrtc],
  );

  const joinRoom = useCallback(
    async (eventId: string, roomName: string, redirect = true) => {
      // disconnectFromRoom();

      try {
        if (!floors.length) createFloor(eventId);

        if (roomName && wrtc.joinFloor) {
          const onJoinRoomSuccess = () => {
            console.log('onJoinRoomSuccess: ', roomName);
            toast('Você entrou na sala com sucesso!', { type: 'success' });

            if (roomName && redirect) {
              window.location.pathname = `/rooms/${eventId}/room/${roomName}`;
            }
          };
          const WRTCRoomName = convertToWRTCRoomName(roomName);
          const spotIndex = getSpotIndexFromWRTCRoomName(WRTCRoomName);

          const foundFloorToJoin = floors.find(
            item => item?.name === `floor_${eventId}`,
          );

          if (!foundFloorToJoin) createFloor(eventId);

          await wrtc.joinFloor(
            `floor_${eventId}`,
            0,
            async () => {
              if (wrtc.setVirtualRoomFrameSize)
                wrtc.setVirtualRoomFrameSize(15, 10);

              if (wrtc?.joinRoom) {
                console.log('WRTCRoomName', WRTCRoomName);
                console.log('spotIndex', spotIndex);

                try {
                  await wrtc.joinRoom(
                    WRTCRoomName,
                    spotIndex.toString(),
                    onJoinRoomSuccess,
                    () => toast('Falha ao entrar na sala!', { type: 'error' }),
                  );
                } catch (error) {
                  console.log('catch 2: ', error);
                }
              }
            },
            e => {
              console.log('###e: ', e);

              toast('Falha ao entrar no evento!', { type: 'error' });
            },
          );
        }
      } catch (e) {
        if (String(e) === 'Already connected') return;
        console.log('error: ', e);

        toast(String(e), { type: 'error' });
      }
    },
    [createFloor, floors, history, wrtc],
  );

  const muteMic = useCallback(() => {
    setIsMicMuted(prev => {
      if (wrtc.setLocalInputDeviceMuteState)
        wrtc.setLocalInputDeviceMuteState(!prev);

      return !prev;
    });
  }, [wrtc]);

  const sendChatMessageWrtc = useCallback(
    (data: any) => {
      if (wrtc?.sendChatMessage) {
        wrtc.sendChatMessage(data, -1);
      }
    },
    [wrtc],
  );

  useEffect(() => {
    connect();
  }, [connect]);

  useEffect(() => {
    setActiveUsers([]);
  }, [location.pathname]);

  return (
    <WRTCContext.Provider
      value={{
        wrtcIsConnected,
        connect,
        disconnectFromRoom,
        loadFloors,
        joinRoom,
        sendMessage,
        floors,
        activeUsers,
        hasMutedUsers,
        triggerAnimation,
        setTriggerAnimation,
        speakingPeerIds,
        isMicMuted,
        muteMic,
        sendChatMessageWrtc,
      }}
    >
      {children}
    </WRTCContext.Provider>
  );
};

export const useWRTC = (): WRTCContextProps => {
  const context = useContext(WRTCContext);

  if (!context) {
    throw new Error('useWRTC must be used within an WRTCProvider');
  }

  return context;
};

export default WRTCProvider;
