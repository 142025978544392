import { ListRoomLite as UsecaseReduxListRoomLite } from 'domain/usecases/roomLite/redux';
import { ListRoomLite as UsecaseRemoteListRoomLite } from 'domain/usecases/roomLite/remote';

import { RemoteListRoomLite } from 'data/repository/roomLite';
import { ReduxListRoomLite } from 'data/store/reducer/roomLite/usecases';
import { makeApiUrl, makeHttpClient } from '../../http';

/**
 * send request via API.
 * @returns {UsecaseRemoteListRoomLite}
 * @constructor
 * @see {@link src/data/repository/room-lite/RemoteListRoomLite.ts}
 */
export const makeRemoteListRoomLite = (): UsecaseRemoteListRoomLite =>
  new RemoteListRoomLite(makeApiUrl('/rooms/'), makeHttpClient());

// /**
//  * send request via REDUX.
//  * @returns {UsecaseReduxListRoomLite}
//  */
export const makeReduxListRoomLite = (): UsecaseReduxListRoomLite =>
  new ReduxListRoomLite();
