import styled, { css } from 'styled-components';
import { InputContainer } from '../../UI/Input/styles';

const padding = css`
  padding: 2.4rem;
`;

export const InputPassword = styled(InputContainer)`
  background-color: ${({ theme }) => theme.colors.red1};
`;

export const Footer = styled.div`
  ${padding}
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

type AccessPropsInputContainer = {
  access: 'PUBLIC' | 'RESTRICT' | string;
};

export const AccessInputContainer = styled.div<AccessPropsInputContainer>`
  ${({ access }) => css`
    display: ${access === 'PUBLIC' ? 'none' : 'flex'};
    opacity: ${access === 'PUBLIC' ? 0 : 1};
  `}
  transition: all 0.2s ease-in-out;
`;
