import styled, { css } from 'styled-components';

type UserAvatarContainerProps = {
  micDisabled?: boolean;
  isSpeaking?: boolean;
};

export const UserAvatarContainer = styled.div<UserAvatarContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 0.2rem solid
    ${({ theme, micDisabled }) =>
      micDisabled ? '#211414' : theme.colors.black3};
  border-radius: 0.8rem;

  transition: border-color 0.2s ease-in-out;

  ${({ theme, micDisabled }) =>
    !micDisabled &&
    css`
      &:hover {
        border-color: ${theme.colors.white2};

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 0.8rem;
          background-color: ${theme.colors.white2};
          opacity: 0.3;
          z-index: 1;
        }
      }
    `}

  ${({ isSpeaking, theme }) =>
    isSpeaking &&
    css`
      z-index: 0;
      box-shadow: 0 3px 20px ${`${theme.colors.white1}99`};
    `}
`;
