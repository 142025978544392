import styled, { css } from 'styled-components';
import { TagAreaProps } from './types';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const ContainerTagArea = styled.label.attrs({
  className: 'container-tag-area',
})<Pick<TagAreaProps, 'error'>>`
  width: 100%;
  height: 16rem;
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1.6rem;
  gap: 1rem;

  background: ${({ theme }) => theme.colors.black4};
  border-radius: 0.6rem;
  max-height: 16rem;

  overflow-y: auto;
  overflow-x: hidden;

  margin-bottom: 0;

  ${({ error, theme }) =>
    error &&
    css`
      border: 0.2rem solid ${theme.colors.red1};
    `};

  &:focus-visible,
  &:focus-within {
    outline: 0.2rem solid
      ${({ theme, error }) => theme.colors[error ? 'red1' : 'blue1']};
    box-shadow: 0 0 1rem
      ${({ theme, error }) => theme.colors[error ? 'red1' : 'blue1']};
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0.3rem;
  }
`;

export const InputTagArea = styled.input`
  width: ${({ width }) => width || '100%'};
  align-self: center;
  overflow-x: visible;
  height: 2rem;
  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.white1};

  &::placeholder {
    color: ${({ theme }) => theme.colors.white3};
    font-size: 1.4rem;
  }
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.85rem 2rem;
  gap: 1rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.white5};
  border-radius: 0.5rem;
`;

export const CloseIconButton = styled.div`
  margin-left: 1rem;
  width: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;
