import { toast } from 'react-toastify';

import { GuestLogin } from 'domain/usecases/auth/remote';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import { UnexpectedError, BadRequestError, NotFound } from 'domain/errors';

export class RemoteAuthGuestLogin implements GuestLogin {
  private readonly url: string;

  private readonly httpClient: HttpClient<GuestLogin.Model>;

  constructor(url: string, httpClient: HttpClient<GuestLogin.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  login = async (params: GuestLogin.Params): Promise<GuestLogin.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.forbidden:
        toast.error('Credenciais inválidas!');
        throw new NotFound();
      default:
        toast.error('Falha ao realizar login!');
        throw new UnexpectedError();
    }
  };
}
