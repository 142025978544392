import styled, { keyframes } from 'styled-components';

export const MessageInfo = styled.span`
  width: 32.8rem;
  color: ${({ theme }) => theme.colors.white1};

  font-size: 1.4rem;
  font-weight: 400;
  line-height: normal;
`;

const shakeInput = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-0.4rem);
  }
  50% {
    transform: translateX(0.4rem);
  }
  75% {
    transform: translateX(-0.4rem);
  }
  100% {
    transform: translateX(0);
  }
`;

type InputAnimatedProps = {
  error?: boolean;
};

export const InputAnimated = styled.div<InputAnimatedProps>`
  .animate {
    animation: ${shakeInput} 0.2s ease-in-out 3;
  }
`;
