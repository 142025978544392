import {
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
  RoomLiteTypes,
} from 'domain/interfaces/redux/roomLite/getAll';
import { ListRoomLite } from 'domain/usecases/roomLite/redux/GetAllRoomLite';

export const getAllRequest = (payload: ListRoomLite.Params): iActionGetAll => ({
  type: RoomLiteTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: ListRoomLite.Model,
): iActionGetAllSuccess => ({
  type: RoomLiteTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: RoomLiteTypes.GETALL_FAILED,
});
