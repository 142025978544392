import { call, put } from 'redux-saga/effects';

import {
  loginFailed,
  loginSuccess,
} from 'data/store/reducer/auth/actions/login';

import { makeRemoteLogin } from 'main/factories/usecases/auth/LoginFactory';

import {
  iActionLogin,
  iActionLoginSuccess,
} from 'domain/interfaces/redux/auth/Login';

import { iAuth } from 'domain/interfaces/models';
import { Login } from 'domain/usecases/auth/redux';

import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { makeRemoteGuestLogin } from 'main/factories/usecases/auth/GuestLoginFactory';
import { GuestLogin } from 'domain/usecases/auth/remote';

export function* onLogin(action: iActionLogin) {
  const user = makeRemoteLogin();
  const guest = makeRemoteGuestLogin();

  try {
    if (action.payload.guest) {
      const nameSplited = action.payload.login.split(' ');
      const firstName = nameSplited[0];
      const lastName = nameSplited[nameSplited.length - 1];

      const guestUser: Login.Model['user'] = {
        birthdate: new Date().toLocaleDateString(),
        cpf: '412.854.854-12',
        email: 'teste@convidado.com',
        firstName,
        fullName: action.payload.login,
        lastName: nameSplited.length > 1 ? lastName : '',
        phone: '+55 (11) 99999-9999',
        username:
          nameSplited.length > 1 ? `${firstName} ${lastName}` : `${firstName}`,
      };

      const responseGuestLogin: GuestLogin.Model = yield call(guest.login, {
        name: action.payload.login,
      });

      const mapToRedux: Login.ModelToRedux = {
        accessToken: responseGuestLogin.accessToken,
        accessTokenExpiration: responseGuestLogin.accessTokenExpiration,
        loading: false,
        refreshToken: responseGuestLogin.refreshToken,
        refreshTokenExpiration: responseGuestLogin.refreshTokenExpiration,
        user: guestUser,
        wrtcInfo: {} as iAuth['wrtcInfo'],
      };

      yield put(loginSuccess(mapToRedux));
    } else if (action.payload.socialUser) {
      const { socialUser } = action.payload;

      const responseGuestLogin: GuestLogin.Model = yield call(guest.login, {
        name: action.payload.login,
      });

      const mapToRedux: Login.ModelToRedux = {
        accessToken: responseGuestLogin.accessToken,
        accessTokenExpiration: responseGuestLogin.accessTokenExpiration,
        loading: false,
        refreshToken: responseGuestLogin.refreshToken,
        refreshTokenExpiration: responseGuestLogin.refreshTokenExpiration,
        user: {
          birthdate: socialUser.metadata.creationTime,
          cpf: '412.854.854-12',
          email: socialUser.email ?? 'teste@social.com',
          firstName: socialUser?.displayName?.split(' ')?.[0] ?? 'Anônimo',
          fullName: socialUser?.displayName ?? 'Anônimo',
          lastName: socialUser?.displayName?.split(' ')?.[1] ?? 'Anônimo',
          phone: socialUser?.phoneNumber ?? '+55 (11) 99999-9999',
          username: socialUser?.displayName ?? 'Anônimo',
        },
        wrtcInfo: {} as iAuth['wrtcInfo'],
      };

      yield put(loginSuccess(mapToRedux));
    } else {
      const responseLogin: Login.Model = yield call(user.login, action.payload);

      const mapToRedux: Login.ModelToRedux = {
        accessToken: responseLogin.accessToken,
        accessTokenExpiration: responseLogin.accessTokenExpiration,
        loading: false,
        refreshToken: responseLogin.refreshToken,
        refreshTokenExpiration: responseLogin.refreshTokenExpiration,
        user: responseLogin.user,
        wrtcInfo: {} as iAuth['wrtcInfo'],
      };

      yield put(loginSuccess(mapToRedux));
    }
  } catch (e) {
    yield put(loginFailed());
  }
}

export function onLoginSuccess(action: iActionLoginSuccess) {
  // History.push('/appointment');
  makeReduxActiveMessage().active({
    active: MessageOptions.none,
  });
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onLoginFailed() {}
