import { IconUserLite } from 'presentation/base/icons';
import styled from 'styled-components';

interface iEventQuantity {
  isFull?: boolean;
}

export const ContainerCard = styled.div`
  width: 27.8rem;
  border-radius: 5px;
  background-color: ${({ theme }) => theme?.colors?.black2};
`;

export const ContentCard = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
`;

export const EventsInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Typography = styled.h2<iEventQuantity>`
  font-size: 1.4rem;
  color: ${({ theme, isFull }) => (isFull ? '#ff6666' : theme?.colors?.white6)};
  font-weight: 400;
`;

export const EventParticipants = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const EventTitle = styled.h1`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${({ theme }) => theme?.colors?.white2};
  margin-top: 3px;
`;

export const ContainerTags = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 9px;
  height: 100%;
`;

interface iLockedDiv {
  isPrivate?: boolean;
}
export const LockedDiv = styled.div<iLockedDiv>`
  display: ${({ isPrivate }) => (isPrivate ? 'flex' : 'none')};
  align-items: center;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 8px 4px 8px;
  border: 1px solid ${({ theme }) => theme?.colors?.black3};
  border-radius: 5px;
  font-size: 1.2rem;
  color: ${({ theme }) => theme?.colors?.white1};
  text-align: center;
`;

export const Footer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  #button_click {
    width: 20.2rem;
  }

  button:last-child {
    width: 3.6rem;
    height: 3.6rem;
  }
`;

export const UserIconStyled = styled(IconUserLite)<iEventQuantity>`
  width: 1.2rem;
  height: 1.2rem;
  path {
    fill: ${({ theme, isFull }) =>
      isFull ? '#ff6666' : theme?.colors?.white6};
  }
`;
