import { defaultTheme } from 'presentation/base/themes';
import { StylesConfig } from 'react-select';
import styled, { css } from 'styled-components';

interface IContainerProps {
  size: 'small' | 'medium' | 'large' | 'full';
}

interface SelectProps {
  color: keyof typeof defaultTheme.colors;
  placeholder?: string;
  error?: boolean;
}

export const Container = styled.div<IContainerProps>`
  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          width: 10rem;
        `;
      case 'medium':
        return css`
          width: 12.3rem;
        `;
      case 'large':
        return css`
          width: 18.5rem;
        `;
      case 'full':
        return css`
          width: 100%;
        `;

      default:
        return css`
          width: auto;
        `;
    }
  }};
`;

type customStylesProps = {
  status?: 'error' | 'success';
  error?: boolean;
};

export const customStyles = ({
  status,
  error,
}: customStylesProps): StylesConfig => ({
  container(base, props) {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginBottom: '1.2rem',

      transition: 'border-color 0.2s ease-in-out',
    };
  },
  indicatorSeparator(base, props) {
    return {
      ...base,
      display: 'none',
    };
  },
  control(base, props) {
    const cssByStatus = {
      error: {
        borderColor: defaultTheme.colors.red1,
      },
      success: {
        borderColor: defaultTheme.colors.green1,
      },
    };
    return {
      ...base,
      boxShadow: 'none',
      border: `0.2rem solid ${
        error ? defaultTheme.colors.red1 : 'transparent'
      }`,
      borderRadius: '0.6rem',
      background: defaultTheme.colors.black4,
      cursor: 'pointer',
      width: '100%',
      maxHeight: '3.6rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      transition: 'background 0.2s ease-in-out',

      color: defaultTheme.colors.white2,

      padding: '0 1.6rem',

      '&.select__control--is-disabled': {
        cursor: 'default',
        background: defaultTheme.colors.black1,

        '.select__dropdown-indicator svg path': {
          fill: defaultTheme.colors.white3,
        },

        '&:hover': {
          borderColor: 'transparent',
          background: defaultTheme.colors.black1,
        },
      },

      '&:hover': {
        outline: 'none',
        borderColor: error
          ? defaultTheme.colors.red1
          : defaultTheme.colors.white5,
        background: defaultTheme.colors.black3,
      },

      '&.select__control--is-focused': {
        outline: 'none',
        border: `0.2rem solid ${defaultTheme.colors[error ? 'red1' : 'blue1']}`,
        boxShadow: `0px 0px 10px ${
          defaultTheme.colors[error ? 'red1' : 'blue1']
        }`,
      },
    };
  },

  dropdownIndicator(base, props) {
    return {
      ...base,
      padding: 0,
    };
  },

  placeholder(base, props) {
    const { isDisabled } = props;
    return {
      ...base,
      fontSize: '1.4rem',
      color: isDisabled
        ? defaultTheme.colors.white3
        : defaultTheme.colors.white2,
    };
  },

  singleValue(base, props) {
    const { isDisabled } = props;

    return {
      ...base,
      fontSize: '1.4rem',
      color: isDisabled
        ? defaultTheme.colors.white3
        : defaultTheme.colors.white2,
    };
  },

  valueContainer(base, props) {
    return {
      ...base,
      fontSize: '1.4rem',
      color: defaultTheme.colors.white2,
      padding: 0,
    };
  },

  input(base, props) {
    return {
      ...base,
      padding: 0,
      margin: 0,
    };
  },

  option(base, props) {
    const { isSelected, isFocused } = props;

    const colorToBackgroundByStatus = () => {
      if (isSelected) {
        return defaultTheme.colors.blue2;
      }

      return isFocused
        ? defaultTheme.colors.black3
        : defaultTheme.colors.black4;
    };

    return {
      ...base,
      background: colorToBackgroundByStatus(),
      border: `0.1rem solid ${defaultTheme.colors.black3}`,
      color: defaultTheme.colors.white1,
      cursor: 'pointer',

      fontSize: '1.4rem',
      fontWeight: 400,

      transition: 'background 0.3s ease',
    };
  },

  menu(base, props) {
    return {
      ...base,
      background: defaultTheme.colors.black4,
      padding: 0,
      borderRadius: '0.5rem',

      '&.select__option--is-selected': {
        background: defaultTheme.colors.blue2,
      },
    };
  },

  menuList(base, props) {
    return {
      ...base,
      padding: 0,
      borderRadius: '0.5rem',
    };
  },

  menuPortal(base, props) {
    return {
      ...base,
      zIndex: 9000,
    };
  },
});

export const Label = styled.label<{ required?: boolean }>`
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
  ${({ required, theme }) =>
    required
      ? css`
          &:after {
            content: '*';
            color: ${theme.colors.red1};
          }
        `
      : css`
          &:after {
            content: ' (opcional)';
          }
        `};
`;

export const StNoOptionsMessage = styled.p`
  margin: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
`;

export const StyledOption = styled.div<{
  isDisabled: boolean;
}>`
  filter: ${({ isDisabled }) =>
    isDisabled ? 'brightness(75%)' : 'brightness(100%)'};
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};

  &:hover {
    background: ${({ theme, isDisabled }) =>
      !isDisabled && theme.colors.black5};
  }
`;
