/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-wrap-multilines */
import { IconHelp, IconPlus } from 'presentation/base/icons';
import Header from 'presentation/components/Header';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { iStore } from 'domain/interfaces/models';
import { iEventItem } from 'domain/interfaces/models/Event';
import { makeReduxUpdateWRTCInfo } from 'main/factories/usecases/auth/UpdateWRTCInfoFactory';

import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { makeArrayLengthMultipleOfN } from 'utils/makeArrayLengthMultipleOfN';
import Card from '../Card';
import { Button } from '../UI';
import FloatButton from '../UI/FloatButton';
import {
  Chips,
  Container,
  ContainerEvents,
  ContentChips,
  ContentEvents,
  HeaderCategories,
} from './styles';

interface CarouselItem {
  cover: string;
  title: string;
  event?: iEventItem;
}

interface iEvents {
  sportsCount: number;
  musicCount: number;
  entertainmentCount: number;
  events: iEventItem[];
}

const options = ['Todos', 'Esporte', 'Música', 'Entretenimento', 'Turfe'];

const MainPage: React.FC = () => {
  const { results, loading } = useSelector((store: iStore) => store.roomLite);
  const { accessToken } = useSelector((store: iStore) => store.auth);

  const [hover, setHover] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);

  const openModalToCreateRoom = () => {
    if (!accessToken) {
      makeReduxActiveMessage().active({
        active: MessageOptions.loginModal,
      });
      return;
    }
    makeReduxActiveMessage().active({
      active: MessageOptions.createRoomLiteModal,
    });
  };
  const eventsPerPage = 10;

  const loaderRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(entities => {
      const target = entities[0];

      if (target.isIntersecting) {
        setCurrentPage(old => old + 1);
      }
    }, observerOptions);

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
  }, []);

  useEffect(() => {
    makeReduxUpdateWRTCInfo().update({ insideRoom: false });
  }, []);

  const mappedEvents = useMemo(() => {
    if (!results?.records) return [];
    return makeArrayLengthMultipleOfN(results?.records, 4);
  }, [results]);

  const currentEvents = useMemo(() => {
    const indexOfLastEvent = currentPage * eventsPerPage;

    return mappedEvents?.slice(0, indexOfLastEvent);
  }, [currentPage, mappedEvents]);

  return (
    <Container>
      {!!accessToken && (
        <FloatButton icon={IconPlus} action={openModalToCreateRoom}>
          Criar uma sala
        </FloatButton>
      )}
      <Header />
      <HeaderCategories>
        <div className="content">
          <ContentChips>
            {options?.map((option, index) => (
              <>
                <Chips
                  active={selectedOption === index}
                  key={option}
                  onClick={() => setSelectedOption(index)}
                >
                  {option}
                </Chips>
              </>
            ))}
          </ContentChips>
          <Button
            icon={IconHelp}
            colorstyle="black3"
            size="very small"
            iconSize={20}
          />
        </div>
      </HeaderCategories>
      <ContainerEvents>
        <ContentEvents>
          {currentEvents?.map(event => (
            <Card event={event} />
          ))}
        </ContentEvents>
        <p
          ref={loaderRef}
          style={{
            textAlign: 'center',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            color: '#fff',
            display:
              currentEvents?.length === mappedEvents?.length ? 'none' : 'block',
          }}
        >
          Carregando...
        </p>
      </ContainerEvents>
    </Container>
  );
};
export default MainPage;
