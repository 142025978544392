import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { MessageProps } from '../components/Chat/interfaces';
import { iAnimation } from '../pages/Room/interface';

type MessagesPublicChatContextProps = {
  chat: any;
  messages: MessageProps[];
  isLoadingServer: boolean;
  isChatEmpty: boolean;
  handleAddNewMessage: (message: any) => void;
  receiveMessage: (message: MessageProps) => void;
  triggerAnimation: iAnimation;
  setTriggerAnimation: (animation: iAnimation) => void;
};

export const MessagesPublicChatContext =
  createContext<MessagesPublicChatContextProps>(
    {} as MessagesPublicChatContextProps,
  );

const MessagesPublicChatProvider: React.FC = ({ children }) => {
  const isLoadingServer = false;

  const [messages, setMessages] = useState<any[]>([]);
  const [isChatEmpty, setIsChatEmpty] = useState(true);
  const [triggerAnimation, setTriggerAnimation] = useState<iAnimation>(
    {} as iAnimation,
  );

  const { wrtcInfo, user } = useSelector((store: iStore) => store.auth);

  const chat = useMemo(() => {
    const newChat = {
      id: wrtcInfo?.peerId,
      messages,
      isPublic: true,
      isOpen: true,
      isDisplayed: true,
      to: {
        id: -1,
        name: '',
        avatar: '',
        status: 'online',
      },
      from: {
        id: wrtcInfo?.peerId ?? 0,
        name: user?.fullName ?? '',
        avatar: '',
        status: 'online',
      },
    };
    return newChat;
  }, [messages, user?.fullName, wrtcInfo?.peerId]);

  const handleAddNewMessage = useCallback(message => {
    setMessages(prevState => [...prevState, message]);
  }, []);

  const updateReaction = useCallback(
    (peerId: number, animationUrl: string, audioUrl: string) => {
      setTriggerAnimation({
        peerId,
        animationUrl,
        audioUrl,
      });
    },
    [],
  );

  const receiveMessage = useCallback(
    (message: MessageProps) => {
      if (message && message.messageType === 'chat') {
        handleAddNewMessage(message);
      }
      if (message && message.messageType === 'reaction')
        updateReaction(
          message.userId,
          String(message.animationUrl),
          String(message.audioUrl),
        );
    },
    [handleAddNewMessage, updateReaction],
  );

  useEffect(() => {
    if (messages.length > 0) {
      setIsChatEmpty(false);
    } else {
      setIsChatEmpty(true);
    }
  }, [messages]);

  return (
    <MessagesPublicChatContext.Provider
      value={{
        chat,
        messages,
        isLoadingServer,
        isChatEmpty,
        handleAddNewMessage,
        receiveMessage,
        triggerAnimation,
        setTriggerAnimation,
      }}
    >
      {children}
    </MessagesPublicChatContext.Provider>
  );
};

export const useChatMessage = (): MessagesPublicChatContextProps => {
  const context = useContext(MessagesPublicChatContext);

  if (!context) {
    throw new Error(
      'useChatMessage must be used within an MessagesPublicChatProvider',
    );
  }

  return context;
};

export default MessagesPublicChatProvider;
