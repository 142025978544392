import { defaultTheme } from 'presentation/base/themes';
import styled, { css } from 'styled-components';

interface ButtonStyleProps {
  size?: 'very small' | 'small' | 'medium' | 'large' | 'dynamic';
  social?:
    | 'google'
    | 'facebook'
    | 'twitter'
    | 'telegram'
    | 'whatsapp'
    | 'linkedin';
  colorstyle?: keyof typeof defaultTheme.colors | null;
  hoverColor?: keyof typeof defaultTheme.colors;
  iconSize?: number;
  withInput?: boolean;
  variant?: 'primary' | 'secondary';
}

export const ButtonStyleDisabled = css`
  &:disabled {
    background-color: ${({ theme }) => theme.colors.black1};
    color: ${({ theme }) => theme.colors.white5};
    h4 {
      font-weight: 600;
    }
  }
`;

export const ButtonStylePrimary = css`
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.blue2};
  color: ${({ theme }) => theme.colors.white1};

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.blue1};
    color: ${({ theme }) => theme.colors.white2};
  }

  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.blue1};
    color: ${({ theme }) => theme.colors.white2};
    outline: 0.2rem solid ${({ theme }) => theme.colors.white2};
    box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.colors.white2};
  }

  &:active:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.blue2};
    color: ${({ theme }) => theme.colors.blue1};
    box-shadow: none;
  }

  ${ButtonStyleDisabled}
`;

export const ButtonStyleSecondary = css`
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.black3};
  color: ${({ theme }) => theme.colors.white1};

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.white5};
    color: ${({ theme }) => theme.colors.white2};
  }

  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.black3};
    color: ${({ theme }) => theme.colors.white2};
    outline: 0.2rem solid ${({ theme }) => theme.colors.blue1};
    box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.colors.blue1};
  }

  &:active:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.black3};
    outline: 0.15rem solid ${({ theme }) => theme.colors.blue2};
    color: ${({ theme }) => theme.colors.blue1};
    box-shadow: none;
  }

  ${ButtonStyleDisabled}
`;

export const ButtonStyle = styled.button<ButtonStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 8.5px;

  background-color: ${({ theme, colorstyle }) => {
    return theme.colors[colorstyle ?? 'blue2'];
  }};

  /* border: ${({ theme, colorstyle }) =>
    colorstyle === 'black2' ? `1px solid ${theme.colors.blue1}` : 'none'}; */

  border: none;

  border-radius: ${({ withInput }) =>
    withInput ? '0 0.5rem 0.5rem 0' : '0.5rem'};

  transition: background-color 0.3s;

  h4 {
    font-weight: 600;
    color: ${({ theme, colorstyle }) => {
      switch (colorstyle) {
        case 'white1':
          return theme.colors.black1;
        default:
          return theme.colors.white1;
      }
    }};
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:focus-visible {
    outline: 2px solid ${props => props.theme.colors.blue1};
    background-color: ${props => props.theme.colors.black3};
    box-shadow: 0 0 1rem 0 ${props => props.theme.colors.blue1};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.black1};
    cursor: not-allowed;

    h4 {
      color: ${({ theme }) => theme.colors.white5};
    }
  }

  &:hover:not(:disabled) {
    background-color: ${({ theme, hoverColor }) => {
      return theme.colors[hoverColor ?? 'blue1'];
    }};
  }

  &:active:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.black3};
    outline: 1.5px solid ${props => props.theme.colors.blue2};
    box-shadow: initial;

    h4 {
      color: ${props => props.theme.colors.blue1};
    }
  }

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
      &:hover {
        background-color: ${color};
        filter: brightness(0.9);
      }
    `}

  svg.iconButton {
    width: ${props => (props.iconSize ? `${props.iconSize}px` : '10px')};
    height: ${props => (props.iconSize ? `${props.iconSize}px` : '10px')};
  }

  ${({ variant }) => variant === 'primary' && ButtonStylePrimary}
  ${({ variant }) => variant === 'secondary' && ButtonStyleSecondary}
`;

export const ButtonSocial = styled.button<ButtonStyleProps>`
  width: ${props => {
    switch (props.size) {
      case 'very small':
        return '34px';
      case 'small':
        return '78px';
      case 'medium':
        return '149px';
      case 'large':
        return '356px';
      default:
        return '100%';
    }
  }};
  height: 33px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme, social }) => {
    switch (social) {
      case 'google':
        return theme.colors.white1;
      case 'twitter':
        return theme.colors.colorButtonTwitter;
      case 'facebook':
        return theme.colors.colorButtonFacebook;
      case 'telegram':
        return theme.colors.colorButtonFacebook;
      case 'whatsapp':
        return theme.colors.colorButtonWhats;
      case 'linkedin':
        return theme.colors.colorButtonLinkedin;
      default:
        return theme.colors.black4;
    }
  }};
  border: none;
  border-radius: 5px;

  transition: background-color 0.3s;

  h4 {
    color: ${({ theme, social }) => {
      switch (social) {
        case 'facebook':
          return theme.colors.white1;
        case 'twitter':
          return theme.colors.white1;
        default:
          return theme.colors.colorTextGoogle;
      }
    }};
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-align: center;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.black5};
    opacity: 0.3;
    cursor: not-allowed;

    h4 {
      color: ${({ theme }) => theme.colors.white1};
    }

    :hover {
      background-color: ${({ theme }) => theme.colors.black5};
    }
  }

  &:hover {
    background-color: ${({ theme, social }) => {
      switch (social) {
        case 'twitter':
          return theme.colors.colorButtonTwitterHover;
        case 'facebook':
          return theme.colors.colorButtonFacebookHover;
        case 'whatsapp':
          return theme.colors.colorButtonWhatsHover;
        case 'telegram':
          return theme.colors.colorButtonTelegramHover;
        case 'google':
          return theme.colors.colorButtonGoogleHover;
        case 'linkedin':
          return theme.colors.colorButtonLinkedinHover;
        default:
          return theme.colors.white2;
      }
    }};
  }

  svg.iconButton {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
`;
