import styled, { css } from 'styled-components';

const padding = css`
  padding: 2.4rem;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.form`
  width: 100%;
  max-width: 40.8rem;
  background-color: ${({ theme }) => theme.colors.black2};
  color: ${({ theme }) => theme.colors.white1};
  border-radius: 1rem;

  transition: all 0.2s ease-in-out;
`;

export const Title = styled.h2`
  font-size: 1.8rem;
  font-weight: 700;

  display: flex;
  align-items: center;
`;
export const IconTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 3.6rem;
  height: 3.6rem;

  background-color: ${({ theme }) => theme.colors.black8};
  border-radius: 50%;

  margin-right: 1.2rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${padding}
  padding-bottom: 1.2rem;
  padding-top: 1.2rem;

  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.black3};
`;

export const Body = styled.div`
  ${padding}
  gap: 2rem;
  display: flex;
  flex-direction: column;

  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.black3};
`;

export const CloseButtonModal = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  background: transparent;

  padding: 1.1rem;
  border-radius: 0.5rem;
  outline: none;

  &:hover {
    background: ${({ theme }) => theme.colors.black3};
    color: ${({ theme }) => theme.colors.white2};
  }

  &:focus-visible {
    box-sizing: border-box;
    padding: 0.9rem;
    background: ${({ theme }) => theme.colors.black3};
    border: 0.2rem solid ${({ theme }) => theme.colors.blue1};

    box-shadow: 0 0 1rem ${({ theme }) => theme.colors.blue1};

    svg path {
      fill: ${({ theme }) => theme.colors.white2};
    }
  }

  &:active {
    border: 0;
    padding: 1.1rem;
    background: ${({ theme }) => theme.colors.black3};

    svg path {
      fill: ${({ theme }) => theme.colors.blue1};
    }

    box-shadow: initial;
  }

  svg {
    width: 1.6rem;
    aspect-ratio: 1;
  }
`;
