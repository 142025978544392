/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Room from 'presentation/pages/NewRoom';
import { ConfirmUserFactory } from '../factories/pages/ConfirmUser';
import { HomeFactory } from '../factories/pages/Home';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Route exact path="/" component={HomeFactory} />
      <Route exact path="/confirm" component={ConfirmUserFactory} />
      <Route exact path="/rooms/:id/room/:roomId" component={Room} />
    </BrowserRouter>
  );
};

export default Routes;
