/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/react-in-jsx-scope */

import { animalsList } from 'data/repository/mockData/animals.json';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxLogin } from 'main/factories/usecases/auth/LoginFactory';
import { makeReduxGetAllCategory } from 'main/factories/usecases/category/GetAllCategoryFactory';
import { makeReduxGetAllFriendship } from 'main/factories/usecases/friendship/GetAllFriendshipFactory';
import { makeReduxGetAllLayout } from 'main/factories/usecases/layout/GetAllLayoutFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { makeReduxListRoomLite } from 'main/factories/usecases/roomLite/GetAllRoomLiteFactory';
import {
  IconConfiguration,
  IconEmoticon,
  IconMic,
  IconMicMuted,
  IconShare2,
} from 'presentation/base/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import ReactionsContent from 'presentation/components/ReactionsContent';

import { iReactions, iStore } from 'domain/interfaces/models';
import ActionsWindow from 'presentation/components/ActionsWindow';
import Header from 'presentation/components/Header';
import SubHeader from 'presentation/components/SubHeader';
import UserBadge from 'presentation/components/UserBadge';
import { useWRTC } from 'presentation/contexts/WRTCContext';
import { closeModal } from 'utils/modalFunctions';

import AudioProvider from 'presentation/contexts/AudioContext';

import { makeRemoteGetAllReaction } from 'main/factories/usecases/reaction/GetAllReactionFactory';
import { Box } from 'presentation/components/LivePlataform/styles';
import { iParams } from '../Room/layouts';
import {
  Audience,
  Body,
  Container,
  Content,
  ControlsButton,
  ControlsContainer,
  GridAudience,
} from './styles';
import { Actions } from '../../components/FooterInsideRoom/styles';
import { TooltipComponent } from '../../components/TooltipComponent';
import { iGifs } from '../Room/interface';

const chat = {
  id: 1,
  isPublic: true,
  from: {
    id: 1,
    name: 'João',
    avatar: 'https://avatars.githubusercontent.com/u/2254731?v=4',
    status: 'online',
  },
  to: {
    id: 2,
    name: 'Maria',
    avatar: 'https://avatars.githubusercontent.com/u/2254731?v=4',
    status: 'online',
  },
  messages: [],
  isDisplayed: true,
  isOpen: true,
};

const Room: React.FC = () => {
  const [requestedJoin, setRequestedJoin] = useState(false);
  const [openReactions, setOpenReactions] = useState(false);
  const [reactionButtons, setReactionButtons] = useState<Array<iReactions>>([]);
  const [reactionGif, setReactionGif] = useState<Array<iGifs>>([]);
  const [displayedLabels, setDisplayedLabels] = useState<number[]>([]);

  const {
    wrtcIsConnected,
    loadFloors,
    floors,
    activeUsers,
    joinRoom,
    disconnectFromRoom,
    muteMic,
    isMicMuted,
    speakingPeerIds,
    setTriggerAnimation,
    sendChatMessageWrtc,
  } = useWRTC();

  const { id, email } = useSelector((store: iStore) => store.auth.user);
  const { wrtcInfo } = useSelector((store: iStore) => store.auth);
  const { results } = useSelector((store: iStore) => store.roomLite);
  const history = useHistory();
  const location = useLocation();
  const params: iParams = useParams();

  const embed = useMemo(() => location.pathname.includes('embed'), [location]);

  const selectedRoomLite = useMemo(
    () => results?.records?.find(room => room.id === Number(params.id)),
    [params.id, results],
  );

  const handleInviteModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.inviteModal,
      actionCancel: () => closeModal(),
    });
  }, []);

  const handleConfigurationModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.roomsConfigure,
      actionCancel: () => closeModal(),
    });
  }, []);

  const handleReactionClick = useCallback(
    (name: string) => {
      const reaction = reactionButtons.find(item => item.name.includes(name));

      const gifUrl = reaction?.reaction;
      const audioUrl = reaction?.audio;

      if (gifUrl && audioUrl && wrtcInfo?.peerId) {
        setTriggerAnimation({
          peerId: wrtcInfo?.peerId,
          animationUrl: gifUrl,
          audioUrl,
        });

        if (sendChatMessageWrtc)
          sendChatMessageWrtc({
            messageType: 'reaction',
            userId: wrtcInfo?.peerId,
            animationUrl: gifUrl,
            audioUrl,
          });
      }
    },
    [
      reactionButtons,
      sendChatMessageWrtc,
      setTriggerAnimation,
      wrtcInfo?.peerId,
    ],
  );

  useEffect(() => {
    makeReduxListRoomLite().list({
      limit: 9999,
    });

    if (id) makeReduxGetAllFriendship().getAll({ user: Number(id) });

    return () => {
      disconnectFromRoom();
    };
  }, [disconnectFromRoom, id]);

  useEffect(() => {
    if (!email || email === '') {
      makeReduxGetAllLayout().getAll({});
      makeReduxListRoomLite().list({
        limit: 9999,
      });
      makeReduxGetAllCategory().getAll({});
    }
  }, [email]);

  useEffect(() => {
    if (embed && (!email || email === '')) {
      const guestName =
        animalsList[Math.floor(Math.random() * animalsList.length)];

      makeReduxLogin().login({
        login: `${guestName} anônimo`,
        password: 'convidado',
        guest: true,
      });
    }

    if (!embed && (!email || email === '')) {
      makeReduxActiveMessage().active({
        active: MessageOptions.loginModal,
        actionOk: () => closeModal(),
        actionCancel: () => {
          closeModal();
          history.push('/');
        },
      });
    }
  }, [embed]);

  useEffect(() => {
    if (wrtcIsConnected) loadFloors();
  }, [loadFloors, wrtcIsConnected]);

  useEffect(() => {
    if (!params?.id || !params?.roomId || !floors.length || requestedJoin)
      return;

    joinRoom(params?.id, params?.roomId, false);
    setRequestedJoin(true);
  }, [joinRoom, params, floors, requestedJoin]);

  useEffect(() => {
    makeRemoteGetAllReaction()
      .getAll({
        limit: 9999,
      })
      .then(res => {
        const reaction: iReactions[] = [];
        const gifs: iGifs[] = [];
        const displayed: number[] = [];

        res.records.forEach((item, index) => {
          if (item.type === 'GIF')
            gifs.push({
              name: item.name,
              animationUrl: item.url,
              audioUrl: item.audio,
            });
          if (item.type === 'REACTION' && item.enabled) {
            if (displayed.length < 4) displayed.push(item.id);

            reaction.push(item);
          }

          if (item.reaction) reaction.push(item);
        });

        setReactionButtons(reaction);
        setReactionGif(gifs);
        setDisplayedLabels(displayed);
      })
      .catch((err: any) => {
        console.log('Erro ao buscar reações', err);
      });
  }, []);

  return (
    <AudioProvider localMute={muteMic} isMicMuted={isMicMuted}>
      <Container>
        <Header />
        <SubHeader title={selectedRoomLite?.title || 'Sala de bate-papo'} />
        <Body>
          <Content>
            <Audience>
              <GridAudience>
                {activeUsers?.map(spot => (
                  <UserBadge
                    user={{
                      name: spot.peerName,
                      peerId: Number(spot.peerId),
                      role: 'Moderador',
                      isSpeaking: Boolean(speakingPeerIds[spot.peerId]),
                    }}
                  />
                ))}
              </GridAudience>
              <ControlsContainer>
                <ControlsButton onClick={() => muteMic()} muted={isMicMuted}>
                  {isMicMuted ? <IconMicMuted /> : <IconMic />}
                </ControlsButton>

                {reactionButtons && setOpenReactions && handleReactionClick && (
                  <Box>
                    <TooltipComponent
                      title="teste"
                      placement="bottom"
                      tooltipType="reactions"
                      open={openReactions}
                      titleContent={
                        <ReactionsContent
                          reactions={reactionButtons}
                          handleReactionsClick={handleReactionClick}
                          openReactions={openReactions}
                          setOpenReactions={() =>
                            setOpenReactions?.(!openReactions)
                          }
                        />
                      }
                    >
                      <Actions
                        onClick={() => setOpenReactions?.(!openReactions)}
                        id="btn_reaction"
                      >
                        <IconEmoticon />
                      </Actions>
                    </TooltipComponent>
                  </Box>
                )}

                <ControlsButton>
                  <IconShare2 onClick={handleInviteModal} />
                </ControlsButton>

                <ControlsButton>
                  <IconConfiguration onClick={handleConfigurationModal} />
                </ControlsButton>
              </ControlsContainer>
            </Audience>
            <ActionsWindow participants={activeUsers} />
          </Content>
        </Body>
      </Container>
    </AudioProvider>
  );
};

export default Room;
