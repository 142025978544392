import React from 'react';
import ReactLinkify from 'react-linkify';
import { MessageProps } from '../../Chat/interfaces';
import { Container, Message, MessageContainer, UserNickname } from './styles';

interface MessageBallonProps extends React.HTMLAttributes<HTMLDivElement> {
  message?: MessageProps;
  isMe?: boolean;
}

const MessageBallon: React.FC<MessageBallonProps> = ({
  message,
  isMe,
  ...rest
}) => {
  const peerId = message?.chat?.from?.id ?? 0;

  const colors = [
    '#FBFBFB',
    '#51B4FF',
    '#629DFF',
    '#B66AFF',
    '#FF75D0',
    '#FF6666',
    '#FF986B',
    '#FFD56A',
    '#E3FF75',
    '#92ED73',
    '#8AFFCE',
    '#FBFBFB',
  ];

  const normalizedPeerId = Math.round(peerId / 2 ** 16);
  const name = Math.floor(normalizedPeerId % 120);
  const color = colors[Math.floor(name / 10)];

  return (
    <Container isMe={isMe} {...rest}>
      <MessageContainer>
        <UserNickname color={color}>{message?.chat?.from?.name}</UserNickname>
        <Message>
          <ReactLinkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a
                href={decoratedHref}
                key={key}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: '#fff',
                  fontWeight: 'bold',
                  wordBreak: 'break-all',
                }}
              >
                {decoratedText}
              </a>
            )}
          >
            {message?.message}
          </ReactLinkify>
        </Message>
      </MessageContainer>
    </Container>
  );
};

export default MessageBallon;
