/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createFailed,
  createSuccess,
} from 'data/store/reducer/roomLite/actions/create';
import { iActionCreate } from 'domain/interfaces/redux/roomLite/create';
import { CreateRoomLite } from 'domain/usecases/roomLite/remote/CreateRoomLite';
import { makeRemoteCreateRoomLite } from 'main/factories/usecases/roomLite/CreateRoomLiteFactory';
import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { closeModal } from 'utils/modalFunctions';

export function* onCreate(action: iActionCreate) {
  const remoteCreateRoomLite = makeRemoteCreateRoomLite();

  try {
    const response: CreateRoomLite.Model = yield call(
      remoteCreateRoomLite.create,
      action.payload,
    );
    yield put(
      createSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(createFailed());
  }
}

export function onCreateSuccess(): void {
  closeModal();
  toast.success('Sala criada com sucesso!');
}

export function onCreateFailed(): void {}
