/* eslint-disable react/require-default-props */
import React, { forwardRef, memo, useState } from 'react';

import { defaultTheme } from 'presentation/base/themes';
import { IconSearch } from '../../../base/icons';
import { Container, InputContainer, Label, Message } from './styles';

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name?: string;
  label?: string;
  orientation?: 'column' | 'row';
  message?: string;
  width?: string;
  maxWidth?: string;
  height?: string;
  error?: boolean;
  required?: boolean;
  breakpointSize?: 'xs' | 'ss' | 's' | 'm' | 'xl';
  isSearch?: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  color?: keyof typeof defaultTheme.colors;
  btnId?: string;
  onClickBtn?: () => any;
  register?: () => any;
}

const Input: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    label,
    children,
    message,
    error = false,
    width = '100%',
    height = '38px',
    required = false,
    icon: Icon,
    btnId,
    onClickBtn,
    isSearch = false,
    orientation = 'column',
    // value,
    register,
    color = 'black3',
    maxWidth,
    breakpointSize,
    ...rest
  },
  ref,
) => {
  const [focus, setFocus] = useState<boolean>(false);

  const mTop = label ? '9px' : '0px';
  const mBottom = message ? '2px' : '0px';
  const { disabled } = rest;

  const Logo = Icon || IconSearch;

  // if the component are use react-hook-form.
  if (register) register();

  return onClickBtn || isSearch ? (
    <Container
      width={width}
      maxWidth={maxWidth}
      orientation={orientation}
      size={breakpointSize}
    >
      {label && (
        <Label required={required} orientation={orientation}>
          {label}
        </Label>
      )}
      <InputContainer
        style={{ marginTop: mTop, marginBottom: mBottom, ...rest.style }}
        color={color}
        height={height}
        focus={focus}
        disabled={disabled}
        error={error}
        hasButton={Boolean(onClickBtn)}
      >
        <input
          {...rest}
          ref={ref}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        {children}
      </InputContainer>
      {message && <Message error={error}>{message}</Message>}
    </Container>
  ) : (
    <Container width={width} orientation={orientation} size={breakpointSize}>
      {label && (
        <Label required={required} orientation={orientation}>
          {label}
        </Label>
      )}
      <InputContainer
        color={color}
        style={{ marginTop: mTop, marginBottom: mBottom, ...rest.style }}
        height={height}
        focus={focus}
        disabled={disabled}
        error={error}
      >
        <input {...rest} ref={ref} />
        {children}
      </InputContainer>
      {message && <Message error={error}>{message}</Message>}
    </Container>
  );
};

export default memo(forwardRef(Input), (prevProps, nextProps) => {
  return (
    prevProps.value !== nextProps.value && prevProps.error !== nextProps.error
  );
});
