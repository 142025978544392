import styled, { css } from 'styled-components';

type ContainerProps = {
  isMe?: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  background-color: transparent;
  align-items: flex-start;
  justify-content: ${({ isMe }) => (isMe ? 'end' : 'start')};

  ${({ isMe }) =>
    isMe &&
    css`
      .message-container {
        background: ${({ theme }) => theme.colors.blue2};
        color: ${({ theme }) => theme.colors.white2};
        &::before {
          display: none;
        }
      }

      .user-nickname {
        display: none;
      }

      .avatar-container {
        display: none;
      }
    `}
`;

export const MessageContainer = styled.div.attrs({
  className: 'message-container',
})`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 75%;
  background-color: ${({ theme }) => theme.colors.black3};
  border-radius: 0.7rem;
  padding: 1.2rem 2rem;
  color: ${({ theme }) => theme.colors.white6};
  position: relative;

  @media (max-width: 480px) {
    max-width: 100%;
  }
`;

export const UserNickname = styled.span.attrs({
  className: 'user-nickname',
})`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${({ color, theme }) => color ?? theme.colors.white1};
`;

export const Message = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const AvatarContainer = styled.div.attrs({
  className: 'avatar-container',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.2rem;
  margin-bottom: 0.4rem;
  max-width: 4rem;
  max-height: 4rem;

  background-color: ${({ theme }) => theme.colors.black3};
  border-radius: 0.6rem;

  overflow: hidden;

  @media (max-width: 480px) {
    display: none;
  }
`;
