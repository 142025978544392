import styled, { css } from 'styled-components';

type ContainerProps = {
  isMobile?: boolean;
  isOpen?: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  color: #fff;
  background: ${({ theme }) => theme.colors.black1};

  ${({ isMobile, isOpen }) =>
    isMobile &&
    css`
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 9999;
      max-height: calc(100vh - 20.2rem - 12rem);

      transform: translateY(${isOpen ? '0' : '100%'});

      visibility: ${isOpen ? 'visible' : 'hidden'};

      transition: transform 0.3s ease-in-out;

      ${Footer} {
        border-radius: 0;
      }
    `}
`;

export const HeaderToBottomSheet = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
  background: ${({ theme }) => theme.colors.black2};
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.black3};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  & #icon-ballon-chat {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.2rem;
    border-radius: 50rem;
    background: ${({ theme }) => theme.colors.black4};
  }
`;

export const TitleLabel = styled.h1`
  color: ${({ theme }) => theme.colors.white1};
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
`;

export const CloseButton = styled.button`
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.8rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border-color: transparent;
  border-radius: 0.8rem;

  transition: background 0.3s ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.colors.black5};
  }

  &:focus-visible {
    outline: 0.2rem solid ${({ theme }) => theme.colors.blue2};
  }
`;

export const Body = styled.div<Omit<ContainerProps, 'isOpen'>>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 1.6rem 3.2rem 0;
  background: ${({ theme }) => theme.colors.black5};
  gap: 1.2rem;
  height: calc(100% - 7rem);
  max-height: 64rem;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.black4};
    border-radius: 0.4rem;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  padding: 1.6rem;
  background: ${({ theme }) => theme.colors.black2};
  border-radius: 0 0 1rem 1rem;
`;
