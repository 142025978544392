import React from 'react';

import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import { RadioProps } from '@material-ui/core/Radio';
import { Label } from '../UI/Input/styles';
import { BpCheckedIcon, BpIcon, Container } from './styles/RadioStyled';

interface RadioComponentProps {
  title?: string;
  label: string;
  value?: string;
  required?: boolean;
  onClick: (value: any) => void;
  checked: boolean;
  options?: (Partial<FormControlLabelProps> & { value: string })[];
}

const RadioComponent: React.FC<RadioComponentProps> = ({
  title,
  required,
  value,
  label,
  checked,
  onClick,
  options,
}) => {
  function BpRadio(props: RadioProps) {
    return (
      <Radio
        disableRipple
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        color="default"
        focusRipple
        {...props}
      />
    );
  }

  return (
    <Container>
      <Label orientation="column" required={required}>
        {label}
      </Label>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          {options?.map(option => (
            <FormControlLabel
              key={option.value}
              id={option.value}
              value={option.value}
              control={<BpRadio id="radio" />}
              label={option.label}
              checked={value === option.value}
              onClick={() => onClick(option.value)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Container>
  );
};

export default RadioComponent;
