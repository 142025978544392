import {
  iActionCreate,
  iActionCreateFailed,
  iActionCreateSuccess,
  RoomLiteTypes,
} from 'domain/interfaces/redux/roomLite/create';
import { CreateRoomLite } from 'domain/usecases/roomLite/redux/CreateRoomLite';

export const createRequest = (
  payload: CreateRoomLite.Params,
): iActionCreate => ({
  type: RoomLiteTypes.CREATE,
  payload,
});

export const createSuccess = (
  params: CreateRoomLite.Model,
): iActionCreateSuccess => ({
  type: RoomLiteTypes.CREATE_SUCCESS,
  payload: params,
});

export const createFailed = (): iActionCreateFailed => ({
  type: RoomLiteTypes.CREATE_FAILED,
});
