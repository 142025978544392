import styled, { css, keyframes } from 'styled-components';

type ContainerProps = {
  isMuted?: boolean;
};

const Stretch = keyframes`
  0% {
    height: 4px;
  }
  50% {

    height: 16px;
  }
  100% {
    height: 4px;
  }
`;

export const ContainerBadge = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
  position: relative;

  ${({ isMuted }) =>
    isMuted
      ? css`
          & .container-badge__avatar > img {
            filter: grayscale(100%);
          }

          & ${Name}, & ${UserRole} {
            color: ${({ theme }) => theme.colors.white3};
          }
        `
      : css`
          cursor: pointer;

          &:hover .container-badge__avatar {
            border-color: ${({ theme }) => theme.colors.white1};
          }

          &:hover .container-badge__avatar::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: ${({ theme }) => theme.colors.white1};
            opacity: 0.3;
          }
        `};
`;

export const IsSpeakingAnimate = styled.div<{ isSpeaking: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  height: 5.4rem;
  width: 5.4rem;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s ease-out;

  opacity: ${({ isSpeaking }) => (isSpeaking ? 1 : 0)};

  & div {
    width: 4px;
    height: 4px;
    background: #ffffff;
    border-radius: 100px;
    align-items: center;
    animation: ${Stretch} 0.7s infinite;
  }

  & .center {
    height: 16px;
    animation: ${Stretch} 0.7s infinite reverse;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Name = styled.span`
  font-size: 1.3rem;
  font-weight: 600;
  width: 100%;

  color: ${({ theme }) => theme.colors.white2};
  max-width: 6rem;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const UserRole = styled.span`
  font-size: 1.1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white6};
`;
