import React from 'react';
import {
  IconGreyUser,
  AvatarIcon,
  IconCoin,
  IconSchedule,
  IconStar,
  ThemeIcon,
  ConfigurationIcon,
  HelpIcon,
  FeedBackIcon,
  LanguageIcon,
} from 'presentation/base/icons';
import {
  IconAvatarResult,
  IconClock,
  IconConfiguration,
  IconHelp,
  IconInventory,
  IconLeave,
  IconStatistics,
  IconTranslate,
} from 'presentation/base/icons/iconsToCostumizeAvatar';
import CustomizeAvatarOptions from 'presentation/components/CustomizeAvatarOptions/CustomizeAvatarOptions';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import {
  Container,
  Header,
  AvatarStyle,
  UserName,
  Border,
  Type,
  Text,
  Main,
  RightSpace,
  LeftSpace,
  Content,
  Options,
  Avatar,
  Title,
  Footer,
} from './styles/StyledProfileMenu';
import { RandomProfileUser } from '../randomProfileUser';

interface DrawerMenuProps {
  userName: string;
  handleLogout: () => void;
}

const DrawerMenu: React.FC<DrawerMenuProps> = ({ userName, handleLogout }) => {
  const { peerId } = useSelector((store: iStore) => store.auth.wrtcInfo);

  return (
    <Container>
      <div>
        <Header>
          <Avatar>
            {peerId ? (
              <RandomProfileUser peerId={peerId} />
            ) : (
              <IconGreyUser width={34} height={34} />
            )}
            <div className="info">
              <div className="name">{`${userName}`}</div>
              <div className="values">
                <IconCoin />
                <div className="value">1.000.000</div>
                <IconStar />
                <div className="value">1.000.000</div>
              </div>
            </div>
          </Avatar>
        </Header>
        <Main>
          {/* <AvatarStyle>
            {peerId ? (
              <RandomProfileUser size="220px" peerId={peerId} />
            ) : (
              <IconAvatarResult />
            )}
          </AvatarStyle> */}
        </Main>
        <Title>PAINEL DO USUÁRIO</Title>
        <Border />
        <Content>
          <Options id="icon_avatar" disabled>
            <AvatarIcon />
            <Text>Perfil</Text>
          </Options>
          <Options id="icon_schedule" disabled>
            <IconSchedule />
            <Text>Painel de acompanhamento</Text>
          </Options>
          <Border />
          <Options id="icon_clock" disabled>
            <ThemeIcon />
            <Text>Tema: Escuro</Text>
          </Options>
          <Options id="icon_statistics" disabled>
            <LanguageIcon />
            <Text>Idioma: Português (BR)</Text>
          </Options>
          <Options id="icon_statistics" disabled>
            <ConfigurationIcon />
            <Text>Configurações</Text>
          </Options>
          <Border />
          <Options id="icon_statistics" disabled>
            <HelpIcon />
            <Text>Ajuda</Text>
          </Options>
          <Options id="icon_statistics" disabled>
            <FeedBackIcon />
            <Text>Enviar feedback</Text>
          </Options>
        </Content>
        <Border />
        <div>
          <Options id="icon_logoff" onClick={handleLogout}>
            <IconLeave />
            <Text>Sair</Text>
          </Options>
        </div>
      </div>
      <Footer>© 2022 Netfans. Todos os direitos reservados.</Footer>
    </Container>
  );
};

export default DrawerMenu;
