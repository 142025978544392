import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const schema = yup.object().shape({
  title: yup.string().required('O título da sessão é obrigatório').max(256),
  joinPolicy: yup
    .mixed()
    .oneOf(['PUBLIC', 'RESTRICT'])
    .required('A política de entrada é obrigatória')
    .default('PUBLIC')
    .typeError('A política de entrada é obrigatória'),
  password: yup.string().when('joinPolicy', {
    is: 'RESTRICT',
    then: yup
      .string()
      .required('A senha é obrigatória')
      .min(8, 'A senha deve conter no mínimo 8 caracteres')
      .max(256, 'A senha deve conter no máximo 256 caracteres'),
    otherwise: yup.string().nullable(),
  }),
  keywords: yup.array().of(yup.string()).optional().max(256),
});

export const schemaCreateRoomLite = yupResolver(schema);
