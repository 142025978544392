/* eslint-disable consistent-return */
import { environment } from '../main/environment/environment';

const { REACT_APP_LOCAL_STORAGE_ADJROOMS } = environment;

export const convertFromWRTCRoomName = (name: any) => {
  const arr = name?.split('_');

  return arr[1];
};

export const convertToWRTCRoomName = (name: string): string =>
  `room_${Number(name) - 1}`;

export const getSpotIndexFromWRTCRoomName = (name: string): number => {
  const arr = name?.split('_');

  return Number(arr[1]);
};

export const onSetInsideRoomSpotPeer = (
  spotCoordI: any,
  spotCoordJ: any,
  peerId: any,
  peerName: any,
) => {
  console.debug(
    `onSetInsideRoomSpotPeer - peerName: ${peerName}, i: ${spotCoordI}, j: ${spotCoordJ}`,
  );
};

export const onJoinRoomFailed = () => {
  console.debug('Join room failed');
};

export const updateAdjRooms = (hexagon: any) => {
  const isEven = hexagon.x % 2 === 0;

  const adjHexagons = {
    top: `${String.fromCharCode(65 + hexagon.y)}${hexagon.x - 2}`,
    topLeft: isEven
      ? `${String.fromCharCode(65 + hexagon.y - 1)}${hexagon.x - 1}`
      : `${String.fromCharCode(65 + hexagon.y)}${hexagon.x - 1}`,
    topRight: isEven
      ? `${String.fromCharCode(65 + hexagon.y)}${hexagon.x - 1}`
      : `${String.fromCharCode(65 + hexagon.y + 1)}${hexagon.x - 1}`,
    bottom: `${String.fromCharCode(65 + hexagon.y)}${hexagon.x + 2}`,
    bottomLeft: isEven
      ? `${String.fromCharCode(65 + hexagon.y - 1)}${hexagon.x + 1}`
      : `${String.fromCharCode(65 + hexagon.y)}${hexagon.x + 1}`,
    bottomRight: isEven
      ? `${String.fromCharCode(65 + hexagon.y)}${hexagon.x + 1}`
      : `${String.fromCharCode(65 + hexagon.y + 1)}${hexagon.x + 1}`,
    empty: false,
  };

  // Se for da primeira coluna
  if (hexagon.y === 0 && isEven) {
    adjHexagons.topLeft = '';
    adjHexagons.bottomLeft = '';
    // Se for da última coluna
  } else if (hexagon.y === 15) {
    adjHexagons.topRight = '';
    adjHexagons.bottomRight = '';
  }

  // Se for da primeira linha
  if (hexagon.x === 1) {
    adjHexagons.top = '';
    adjHexagons.topLeft = '';
    adjHexagons.topRight = '';
    // Se for da segunda linha
  } else if (hexagon.x === 2) {
    adjHexagons.top = '';
    // Se for da penúltima linha
  } else if (hexagon.x === 18) {
    adjHexagons.bottom = '';
    // Se for da última linha
  } else if (hexagon.x === 19) {
    adjHexagons.bottom = '';
    adjHexagons.bottomLeft = '';
    adjHexagons.bottomRight = '';
    // Se estiver fora do alcance esperado
  } else if (hexagon.x < 0 || hexagon.x > 19) {
    adjHexagons.top = '';
    adjHexagons.topLeft = '';
    adjHexagons.topRight = '';
    adjHexagons.bottom = '';
    adjHexagons.bottomLeft = '';
    adjHexagons.bottomRight = '';
    adjHexagons.empty = true;
  }

  localStorage.setItem(
    REACT_APP_LOCAL_STORAGE_ADJROOMS,
    JSON.stringify(adjHexagons),
  );
};
