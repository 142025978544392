import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import { BadRequestError, NotFound, UnexpectedError } from 'domain/errors';
import { ListRoomLite } from 'domain/usecases/roomLite/remote';
import 'infra/global/variables';
import { toast } from 'react-toastify';

export class RemoteListRoomLite implements ListRoomLite {
  private readonly url: string;

  private readonly httpClient: HttpClient<ListRoomLite.Model>;

  constructor(url: string, httClient: HttpClient<ListRoomLite.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  list = async (params: ListRoomLite.Params): Promise<ListRoomLite.Model> => {
    let query = '';

    Object.entries(params).forEach(([key, value]) => {
      if (value) query += `${key}=${value}&`;
    });

    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${query}`,
      method: 'get',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
