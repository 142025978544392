import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: background 0.3s ease-in-out;

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

  /* Track */
    ::-webkit-scrollbar-track {
      background-color: transparent !important;
      border-radius: 100px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #fff;
      border-radius: 100px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #fff;
    }
  }

  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    font-family: 'Open Sans', sans-serif;
  }

  html {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    background: ${({ theme }) => theme.colors.black1};


  }

  body {
    width: 100vw;
    top: 0px;
    left: 0px;

    /* background: var(--color_background); */
    background-position: bottom !important;
    background-repeat: no-repeat;
    background-attachment: fixed !important;
    opacity: 1;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0;
    }

    background: ${({ theme }) => theme.colors.black1};

  }

  #root {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    .App {
      width: 100%;
      height: 100vh;
    }
  }

  &.MuiPaper-root {
    background-color: transparent !important;
  }

  /* TODO: Refatorar global styles para lidar melhor com scale de resoluções */
  @media (min-width: 1px) {
    :root {
      font-size: 62.5%;
    }
  }
`;
