import { GuestLogin as UseCaseRemoteGuestLogin } from 'domain/usecases/auth/remote';

import { RemoteAuthGuestLogin } from 'data/repository/auth';
import { makeApiUrl, makeHttpClient } from '../../http';

/**
 * make login via API.
 */
export const makeRemoteGuestLogin = (): UseCaseRemoteGuestLogin =>
  new RemoteAuthGuestLogin(makeApiUrl('/auth/guest'), makeHttpClient());
