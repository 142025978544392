import store from 'data/store';
import { Dispatch } from 'redux';

import { createRequest } from 'data/store/reducer/roomLite/actions/create';

import { iActionCreate } from 'domain/interfaces/redux/roomLite/create';

import { CreateRoomLite } from 'domain/usecases/roomLite/redux';

export class ReduxCreateRoomLite implements CreateRoomLite {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  create = (params: CreateRoomLite.Params): iActionCreate =>
    this.send(createRequest(params));
}
