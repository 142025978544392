import { CreateRoomLite as UsecaseReduxCreateRoomLite } from 'domain/usecases/roomLite/redux';
import { CreateRoomLite as UsecaseRemoteCreateRoomLite } from 'domain/usecases/roomLite/remote';

import { RemoteCreateRoomLite } from 'data/repository/roomLite';
import { ReduxCreateRoomLite } from 'data/store/reducer/roomLite/usecases';
import { makeApiUrl, makeHttpClient } from '../../http';

/**
 * send request via API.
 * @returns {UsecaseRemoteCreateRoomLite}
 * @constructor
 * @see {@link src/data/repository/room-lite/RemoteCreateRoomLite.ts}
 */
export const makeRemoteCreateRoomLite = (): UsecaseRemoteCreateRoomLite =>
  new RemoteCreateRoomLite(makeApiUrl('/rooms/'), makeHttpClient());

// /**
//  * send request via REDUX.
//  * @returns {UsecaseReduxCreateRoomLite}
//  */
export const makeReduxCreateRoomLite = (): UsecaseReduxCreateRoomLite =>
  new ReduxCreateRoomLite();
