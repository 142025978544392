import styled from 'styled-components';

export const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: theme.colors.black3,
  width: 20,
  height: 20,
  // '.Mui-focusVisible &': {
  //   outline: `0.1rem solid ${theme.colors.blue1}`,
  //   outlineOffset: 2,
  // },
  'input:hover ~ &': {
    backgroundColor: theme.colors.white5,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(57,75,89,.5)',
  },

  'input:focus-visible ~ &': {
    outline: `0.2rem solid ${theme.colors.blue1}`,
    outlineOffset: 2,
  },
}));

export const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.colors.blue2,

  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: theme.colors.blue1,
  },

  'input:focus-visible ~ &': {
    outline: `0.2rem solid ${theme.colors.white1}`,
  },
}));

export const Container = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-bottom: 0;

  .MuiIconButton-label {
    color: #fbfbfb;
  }
  .MuiTypography-root {
    font-size: 1.4rem;
  }

  .Mui-focusVisible & {
    outline: 0.1rem solid ${({ theme }) => theme.colors.blue1};
  }

  .MuiSvgIcon-root {
    width: 2.5rem;
    height: 2.5rem;

    color: transparent;
    background: ${({ theme }) => theme.colors.black3};
    border-radius: 50%;
  }
`;
