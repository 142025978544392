/* eslint-disable no-unused-expressions */
import React, { createContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { iStore } from 'domain/interfaces/models';

interface AudioContextProps {
  isMicMuted: boolean;
  getMuteState: (peerId: number) => boolean;
  isActualUser: (peerId: number) => boolean;
  localMute: () => void;
  handleRemoteMute: (peerId: number, x: number, y: number) => void;
  isMuteRemotely: (peerId: number) => boolean;
}
interface iMutedUsers {
  [key: string]: boolean;
}

interface AudioProviderProps {
  localMute: () => void;
  isMicMuted: boolean;
  remoteMute?: (data: any) => void;
  hasMutedUsers?: iMutedUsers;
}

export const AudioContext = createContext<AudioContextProps>(
  {} as AudioContextProps,
);

const AudioProvider: React.FC<AudioProviderProps> = ({
  remoteMute,
  localMute,
  isMicMuted,
  children,
  hasMutedUsers,
}) => {
  const wrtc = useSelector((store: iStore) => store.wrtc);
  const { wrtcInfo } = useSelector((store: iStore) => store.auth);

  const getMuteState = useCallback(
    (peerId: number): boolean => {
      const userMuted = hasMutedUsers?.[peerId];
      return (
        (wrtc?.getRemotePeerInputEdgeGain?.(peerId) === 0 || userMuted) ?? false
      );
    },
    [wrtc, hasMutedUsers],
  );

  const isActualUser = useCallback(
    (peerId: number) => {
      return Number(wrtcInfo?.peerId) === peerId;
    },
    [wrtcInfo?.peerId],
  );

  const handleRemoteMute = useCallback(
    (peerId: number, x: number, y: number) => {
      const isMuted = getMuteState(peerId);

      remoteMute?.({ x, y });

      if (wrtc.setRemotePeerInputEdgeGain) {
        isMuted
          ? wrtc.setRemotePeerInputEdgeGain(peerId, 1)
          : wrtc.setRemotePeerInputEdgeGain(peerId, 0);
      }

      // toast(isMuted ? 'Usuário não mais silenciado!' : 'Usuário silenciado!', {
      //   type: isMuted ? 'info' : 'error',
      // });
    },
    [getMuteState, remoteMute, wrtc],
  );

  const isMuteRemotely = useCallback(
    (peerId: number): boolean => {
      return hasMutedUsers?.[peerId] ?? false;
    },
    [hasMutedUsers],
  );

  return (
    <AudioContext.Provider
      value={{
        isMicMuted,
        handleRemoteMute,
        getMuteState,
        isActualUser,
        localMute,
        isMuteRemotely,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};
export default AudioProvider;
