/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
import { iStore } from 'domain/interfaces/models';
import { iEventItem } from 'domain/interfaces/models/Event';
import React, { useCallback, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import {
  ArrowDownIcon,
  IconMicNewFooter,
  IconReactionsFooter,
  IconShareFooter,
  IconSoundFooter,
  IconMicDisabledFooter,
  IconLogoJockey,
  IconInfo,
  IconMic,
  IconMicMuted,
  IconSound,
} from 'presentation/base/icons';
import { IconConfiguration } from 'presentation/base/icons/iconsToCostumizeAvatar';
import useBreakpoint from 'presentation/hooks/useBreakPoint';
import {
  formattedDate,
  incrementMinutesOnTimeString,
} from 'utils/formattedDate';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { closeModal } from 'utils/modalFunctions';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { useParams } from 'react-router';
import ReactionsContent from '../ReactionsContent';
import VolumeSlider from '../VolumeSlider';
import { TooltipComponent } from '../TooltipComponent';
import { Button } from '../UI';
import {
  AvatarContainer,
  ButtonsContainer,
  FooterContainerResponsive,
  HeaderContainer,
  InfoRoomContainer,
  InfoRoomContent,
  InfoRoomSubtitle,
  InfoRoomTitle,
  VeticalDivider,
  ActionsFooter,
  ScrollContainerFooter,
  AdContainer,
  LeftAdContent,
  LeftAdInfo,
  Title,
  Subtitle,
  RightAdContent,
  Box,
  ActionsMobile,
} from './styles';
import MobileShareLink from '../MobileShareLink';
import MobileReactions from '../MobileReactions';
import VolumeSliderContent from '../VolumeSliderContent';

interface FooterResponsiveProps {
  selectedEvent: iEventItem;
  isMicMuted?: boolean;
  openReactions: boolean;
  reactionsButtons: any;
  setOpenReactions?: (value: boolean) => void;
  handleReactionClick?: (reaction: string) => void;
  handleMuteMicrophone?: () => void;
  handleDescriptionClick?: () => void;
  handleAdInfoClick?: () => void;
}

interface iParams {
  roomId: string;
  id: string;
}

export const FooterResponsive: React.FC<FooterResponsiveProps> = ({
  selectedEvent,
  isMicMuted,
  openReactions,
  reactionsButtons,
  setOpenReactions,
  handleReactionClick,
  handleMuteMicrophone,
  handleDescriptionClick,
  handleAdInfoClick,
}) => {
  const [eventVolume, setEventVolume] = useState(100);
  const [publicVolume, setPublicVolume] = useState(100);

  const playerRef = useRef<any>(null);

  const [showVolumeControl, setShowVolumeControl] = useState(false);
  const [showReactions, setShowReactions] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const { spotsInfo } = useSelector((store: iStore) => store);
  const sizeButton = '4rem';
  const sizeIcon = 15;

  const breakpoint = useBreakpoint();
  const isMobile = breakpoint !== 'xxs';

  const handleInviteModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.inviteModal,
      // actionOk: () => closeModal(),
      actionCancel: () => closeModal(),
    });
  }, []);

  // const handleVolumeModal = useCallback(() => {
  //   makeReduxActiveMessage().active({
  //     active: MessageOptions.volumeModal,
  //     actionCancel: () => closeModal(),
  //   });
  // }, []);

  const handleEventVolumeChange = useCallback((value: number) => {
    setEventVolume(value);

    console.log('playerRef?.current: ', playerRef?.current);

    if (playerRef?.current?.setVolume) {
      console.log('updating playerRef volume: ', value);

      playerRef.current.setVolume(value);
    }
  }, []);

  const handlePublicVolumeChange = useCallback((value: number) => {
    const audio = document.getElementById(`mixed_audio`) as HTMLAudioElement;

    console.log('audio: ', audio);

    setPublicVolume(value);

    if (audio) {
      console.log('updating volume: ', value / 100);

      audio.volume = value / 100;
    }
  }, []);

  const handleConfigurationModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.roomsConfigure,
      actionCancel: () => closeModal(),
    });
  }, []);

  const openNewTab = useCallback((url?: string) => {
    if (!url) return;

    const withHttp = !/^https?:\/\//i.test(url) ? `http://${url}` : url;
    window.open(withHttp, '_blank', 'noopener,noreferrer');
  }, []);

  const params: iParams = useParams();

  return (
    <FooterContainerResponsive>
      {/* <AdContainer>
        <LeftAdContent>
          <IconLogoJockey style={{ width: '20%' }} />
          <LeftAdInfo>
            <Title>{selectedEvent?.sponsor?.name}</Title>
            <Subtitle>{`Ad • ${selectedEvent?.sponsor?.url}`}</Subtitle>
          </LeftAdInfo>
        </LeftAdContent>
        <RightAdContent>
          <Button
            size="dynamic"
            title={selectedEvent?.sponsor?.label}
            color={selectedEvent?.sponsor?.color}
            onClick={() => openNewTab(selectedEvent?.sponsor?.url)}
          />
          <IconInfo
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleAdInfoClick?.();
            }}
          />
        </RightAdContent>
      </AdContainer> */}
      <HeaderContainer>
        <InfoRoomContainer>
          <InfoRoomContent>
            <TooltipComponent placement="top" title="Evento | Sala 1">
              <InfoRoomTitle>{selectedEvent?.name}</InfoRoomTitle>
            </TooltipComponent>
            <InfoRoomSubtitle>
              <span>
                {`Sala ${params?.roomId}`} {' - '}
                {selectedEvent?.schedule
                  ? formattedDate(
                      new Date(selectedEvent?.schedule),
                      selectedEvent?.duration,
                    )
                  : 'Jan 1,2022 - 00h00'}
              </span>
            </InfoRoomSubtitle>
          </InfoRoomContent>
        </InfoRoomContainer>
        <ArrowDownIcon
          width="1rem"
          height="1rem"
          onClick={handleDescriptionClick}
        />
      </HeaderContainer>
      <ButtonsContainer>
        <ScrollContainerFooter horizontal>
          {/* <ActionsFooter id="btn_mute" onClick={handleMuteMicrophone}>
            {isMicMuted ? <IconMicNewFooter /> : <IconMicDisabledFooter />}
            {isMicMuted ? 'Silenciar' : 'Habilitar'}
          </ActionsFooter> */}
          <Box onClick={handleMuteMicrophone} isMic btnActive={isMicMuted}>
            {isMicMuted ? <IconMic /> : <IconMicMuted />}
          </Box>

          <Box
            id="btn_volume"
            onClick={() => setShowVolumeControl(!showVolumeControl)}
          >
            <IconSoundFooter />
          </Box>

          <VolumeSlider
            eventVolume={eventVolume}
            handleEventVolumeChange={handleEventVolumeChange}
            publicVolume={publicVolume}
            handlePublicVolumeChange={handlePublicVolumeChange}
            isDisplayed={showVolumeControl}
            onClose={() => setShowVolumeControl(false)}
          />

          {/* <Box>
            <TooltipComponent
              title="teste"
              placement="bottom"
              tooltipType="volume"
              open={showVolumeControl}
              titleContent={
                <VolumeSliderContent
                  eventVolume={eventVolume}
                  handleEventVolumeChange={handleEventVolumeChange}
                  publicVolume={publicVolume}
                  handlePublicVolumeChange={handlePublicVolumeChange}
                  handleClose={() =>
                    setShowVolumeControl(prevState => !prevState)
                  }
                />
              }
            >
              <ActionsMobile
                onClick={() => setShowVolumeControl(prevState => !prevState)}
                id="btn_sound"
              >
                <IconSound />
              </ActionsMobile>
            </TooltipComponent>
          </Box> */}

          {/* <TooltipComponent
            title="teste"
            placement="bottom"
            tooltipType="reactions"
            open={openReactions}
            titleContent={
              <ReactionsContent
                reactions={reactionsButtons}
                handleReactionsClick={handleReactionClick}
                openReactions={openReactions}
                setOpenReactions={setOpenReactions}
              />
            }
          >
            <ActionsFooter
              onClick={() => setOpenReactions?.(!openReactions)}
              id="btn_reaction"
            >
              <IconReactionsFooter />
              Reações
            </ActionsFooter>
          </TooltipComponent> */}

          <Box
            onClick={() => setOpenReactions?.(!openReactions)}
            id="btn_reaction"
          >
            <IconReactionsFooter />
          </Box>
          <MobileReactions
            reactionsButtons={reactionsButtons}
            handleReactionClick={handleReactionClick}
            openReactions={openReactions}
            setOpenReactions={() => setOpenReactions?.(!openReactions)}
          />

          <Box onClick={() => setShowShare(!showShare)} id="btn_share">
            <IconShareFooter />
          </Box>
          <MobileShareLink
            isDisplayed={showShare}
            onClose={() => setShowShare(false)}
          />

          <Box onClick={handleConfigurationModal}>
            <IconConfiguration />
          </Box>
        </ScrollContainerFooter>
      </ButtonsContainer>
    </FooterContainerResponsive>
  );
};
