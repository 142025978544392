import { iRoomLite } from 'domain/interfaces/models/RoomLite';
import { RoomLiteTypes } from 'domain/interfaces/redux/roomLite/types';
import { RoomLiteActions } from './actions';

export const initialState: iRoomLite = {
  loading: false,
  results: {} as iRoomLite['results'],
};

const reducer = (state = initialState, action: RoomLiteActions): iRoomLite => {
  switch (action.type) {
    case RoomLiteTypes.GETALL:
      return { ...state, loading: true };
    case RoomLiteTypes.GETALL_FAILED:
      return { ...state, loading: false };
    case RoomLiteTypes.GETALL_SUCCESS:
      return { ...state, loading: false, results: action.payload };

    case RoomLiteTypes.CREATE:
      return { ...state, loading: true };
    case RoomLiteTypes.CREATE_FAILED:
      return { ...state, loading: false };
    case RoomLiteTypes.CREATE_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
