import { iStore } from 'domain/interfaces/models';
import { makeReduxGetAllCategory } from 'main/factories/usecases/category/GetAllCategoryFactory';
import { makeReduxGetAllEvent } from 'main/factories/usecases/event/GetAllEventFactory';
import { makeReduxListRoomLite } from 'main/factories/usecases/roomLite/GetAllRoomLiteFactory';
import MainPage from 'presentation/components/MainPage';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useWRTC } from 'presentation/contexts/WRTCContext';

const Home: React.FC = () => {
  const wrtc = useSelector((store: iStore) => store.wrtc);
  const { wrtcInfo, user, loading } = useSelector(
    (store: iStore) => store.auth,
  );

  const { disconnectFromRoom, loadFloors } = useWRTC();

  useEffect(() => {
    if (wrtcInfo?.insideRoom) {
      disconnectFromRoom();
    }
  }, [disconnectFromRoom, wrtc, wrtcInfo]);

  useEffect(() => {
    loadFloors();
  }, [loadFloors]);

  useEffect(() => {
    makeReduxListRoomLite().list({
      limit: 9999,
    });

    makeReduxGetAllEvent().getAll({
      limit: 9999,
    });
    makeReduxGetAllCategory().getAll({});
  }, []);

  return <MainPage />;
};

export default Home;
