/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/react-in-jsx-scope */
import { memo, useCallback, useEffect, useState } from 'react';
import { useWRTC } from 'presentation/contexts/WRTCContext';
import { iAnimation } from 'presentation/pages/Room/interface';
import BaseAnimation from '../BaseAnimation';
import { RandomProfileUser as AvatarUser } from '../randomProfileUser';
import {
  ContainerBadge,
  Info,
  IsSpeakingAnimate,
  Name,
  UserRole,
} from './styles';
import { UserBadgeProps } from './types';

const UserBadge: React.FC<UserBadgeProps> = ({ user }) => {
  const [tempAnimation, setTempAnimation] = useState<iAnimation[]>([]);

  const { triggerAnimation: animation, setTriggerAnimation } = useWRTC();

  const updateReaction = useCallback(
    (peerId: number, animationUrl: string, audioUrl: string) => {
      const animationFound = tempAnimation.find(item => item.peerId === peerId);

      if (animationFound) {
        const newTempAnimation = tempAnimation.map(item => {
          if (item.peerId === peerId) {
            return {
              peerId: item.peerId,
              animationUrl: String(animationUrl),
              audioUrl: String(audioUrl),
              active: true,
              triggerFadeout: false,
            };
          }

          return item;
        });

        setTempAnimation(newTempAnimation);
        return newTempAnimation;
      }
      const newTempAnimation = [
        ...tempAnimation,
        {
          peerId,
          animationUrl,
          audioUrl,
          active: true,
          triggerFadeout: false,
        },
      ];

      setTempAnimation(newTempAnimation);
      return newTempAnimation;
    },
    [tempAnimation],
  );

  const renderAnimation = useCallback(
    (peerId: number) => {
      let renderedAnimation = null;

      tempAnimation.forEach(item => {
        if (item.peerId === peerId && item.active) {
          renderedAnimation = (
            <>
              <BaseAnimation>
                {item.animationUrl ? (
                  <img src={item.animationUrl} alt="reaction" />
                ) : null}
              </BaseAnimation>
              <audio src={item.audioUrl} autoPlay />
            </>
          );
        }
      });

      return renderedAnimation;
    },
    [tempAnimation],
  );

  useEffect(() => {
    if (!animation || !Object.keys(animation).length) return;

    updateReaction(
      animation.peerId,
      animation.animationUrl,
      animation.audioUrl,
    );

    setTimeout(() => {
      setTempAnimation(prevState =>
        prevState.map(item => {
          if (item.peerId === animation.peerId) {
            return {
              peerId: item.peerId,
              animationUrl: item.animationUrl,
              audioUrl: item.audioUrl,
              active: false,
              triggerFadeout: item.triggerFadeout,
            };
          }

          return item;
        }),
      );

      setTriggerAnimation({} as iAnimation);
    }, 2000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  return (
    <div style={{ position: 'relative' }}>
      <ContainerBadge isMuted={user.isMuted} className="user-badge">
        <AvatarUser
          peerId={user.peerId ?? 123416}
          size="4.8rem"
          className="container-badge__avatar"
          style={{ borderRadius: '0.5rem' }}
          micDisabled={user.isMuted}
          isSpeaking={Boolean(user.isSpeaking && !user.isMuted && !!user.name)}
          containerStyle={{
            overflow: 'hidden',
            position: 'relative',
          }}
        />
        <Info>
          <Name>{user.name}</Name>
          <UserRole>{user.role}</UserRole>
        </Info>
        <IsSpeakingAnimate
          isSpeaking={Boolean(user.isSpeaking && !user.isMuted && !!user.name)}
        >
          <div />
          <div className="center" />
          <div />
        </IsSpeakingAnimate>
      </ContainerBadge>
      {renderAnimation(user.peerId)}
    </div>
  );
};

export default memo(UserBadge, (prevProps, nextProps) => {
  return (
    prevProps.user.isSpeaking === nextProps.user.isSpeaking &&
    prevProps.user.isMuted === nextProps.user.isMuted &&
    prevProps.user.name === nextProps.user.name &&
    prevProps.user.peerId === nextProps.user.peerId &&
    prevProps.user.role === nextProps.user.role
  );
});
