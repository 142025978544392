/* eslint-disable @typescript-eslint/no-empty-function */
import { useChatMessage } from 'presentation/contexts/MessagesPublicChatContext';
import { useWRTC } from 'presentation/contexts/WRTCContext';
import React, { HTMLAttributes, memo, useEffect } from 'react';
import InputChat from './Input';
import MessageBallon from './Message';
import { Body, Container, Footer } from './styles';

interface ChatProps extends HTMLAttributes<HTMLDivElement> {
  isMobile?: boolean;
  isOpen?: boolean;
  closeChat?: () => void;
}

const Chat: React.FC<ChatProps> = ({
  isMobile,
  isOpen = true,
  closeChat = () => {},
}) => {
  const { messages } = useChatMessage();
  const { sendMessage } = useWRTC();

  useEffect(() => {
    document.getElementById('scroll-div')?.scrollTo({
      top: document.getElementById('scroll-div')?.scrollHeight,
      left: 0,
      behavior: 'auto',
    });
  }, [messages]);

  return (
    <Container
      tabIndex={isMobile ? 0 : undefined}
      isMobile={isMobile}
      isOpen={isOpen}
    >
      {/* {isMobile && (
        <HeaderToBottomSheet>
          <TitleContainer>
            <div id="icon-ballon-chat">
              <IconBallonChat />
            </div>
            <TitleLabel>Bate-papo ao vivo</TitleLabel>
          </TitleContainer>
          <CloseButton onClick={closeChat}>
            <IconClose />
          </CloseButton>
        </HeaderToBottomSheet>
      )} */}
      <Body id="scroll-div" isMobile={isMobile}>
        {messages?.map((message, idx) => (
          <MessageBallon
            id={`chat-message#${message.userId + idx}`}
            key={`chat-message#${message.userId + idx}`}
            message={message}
            isMe={message.type === 'sent'}
          />
        ))}
      </Body>
      <Footer>
        <InputChat
          tabIndex={0}
          onSend={text => {
            sendMessage(text);
          }}
          placeholder="Digite uma mensagem..."
        />
      </Footer>
    </Container>
  );
};

export default memo(Chat);
