import styled, { DefaultTheme, css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  color: #fff;
`;

export const Body = styled.body`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 2.4rem;
  padding-bottom: 2rem;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  color: #fff;

  width: 100%;
  height: 100%;
  max-width: 116rem;

  gap: 2rem;
`;

export const Audience = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.black5};

  min-width: 68.3rem;

  overflow: hidden;
`;

export const GridAudience = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  padding: 2.5rem;

  flex-wrap: wrap;
  max-height: 30rem;
  overflow-y: auto;
  gap: 3.2rem;
`;

export const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem 2.4rem;

  gap: 1.2rem;

  background-color: ${({ theme }) => theme.colors.black2};
`;

type ControlsButtonProps = {
  theme: DefaultTheme;
  muted?: boolean;
};

const colorByMutedState = ({ theme, muted }: ControlsButtonProps) => {
  if (muted) {
    return {
      background: {
        default: theme.colors.red1,
        hover: theme.colors.red2,
        focus: theme.colors.red2,
        disabled: theme.colors.black1,
      },
      focus: {
        default: theme.colors.white1,
        shadow: theme.colors.white1,
      },
      icon: {
        default: theme.colors.white2,
        hover: theme.colors.white2,
        focus: theme.colors.white2,
        disabled: theme.colors.white5,
      },
    };
  }
  return {
    background: {
      default: theme.colors.black3,
      hover: theme.colors.white5,
      focus: theme.colors.black3,
      disabled: theme.colors.black1,
    },
    focus: {
      default: theme.colors.blue1,
      shadow: theme.colors.blue1,
    },
    icon: {
      default: theme.colors.white1,
      hover: theme.colors.white2,
      focus: theme.colors.white2,
      disabled: theme.colors.white5,
    },
  };
};

export const ControlsButton = styled.button<ControlsButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 4.8rem;
  height: 4.8rem;

  background-color: ${({ theme, muted }) =>
    colorByMutedState({ theme, muted }).background.default};
  border: none;
  border-radius: 0.5rem;

  svg path {
    fill: ${({ theme, muted }) =>
      colorByMutedState({ theme, muted }).icon.default};
  }

  &:hover:not(:disabled) {
    background-color: ${({ theme, muted }) =>
      colorByMutedState({ theme, muted }).background.hover};

    svg path {
      fill: ${({ theme, muted }) =>
        colorByMutedState({ theme, muted }).icon.hover};
    }
  }

  &:disabled {
    background-color: ${({ theme, muted }) =>
      colorByMutedState({ theme, muted }).background.disabled};

    svg path {
      fill: ${({ theme, muted }) =>
        colorByMutedState({ theme, muted }).icon.disabled};
    }
  }

  &:focus-visible {
    outline: 0.2rem solid
      ${({ theme, muted }) =>
        colorByMutedState({ theme, muted }).focus.default};
    background-color: ${({ theme, muted }) =>
      colorByMutedState({ theme, muted }).background.focus};

    ${({ theme, muted }) => css`
      box-shadow: 0px 0px 10px
        ${colorByMutedState({ theme, muted }).focus.shadow};
    `}
`;
