/* eslint-disable react/react-in-jsx-scope */
import { MessageOptions } from 'domain/interfaces/redux/message';
import { IconClose, IconLock } from 'presentation/base/icons';
import { useRef, useState } from 'react';
import { closeModal } from 'utils/modalFunctions';
import { Button, Input } from '../../UI';
import {
  Body,
  CloseButtonModal,
  Content,
  Header,
  IconTitle,
  ModalContainer,
  Title,
} from '../styles';
import { InputAnimated, MessageInfo } from './styles';
import { JoinRoomModalProps } from './types';

const JoinRoomModal: React.FC<JoinRoomModalProps> = ({ message }) => {
  const { active: actualModalActive, roomId } = message;
  const modalIsActive = actualModalActive === MessageOptions.joinRoomModal;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  // TODO: adicionar remote para entrar na sala e tratar erro e feedback para o usuário
  const joinRoom = (password: string) => {
    const passwordMock = '123456';
    setLoading(true);
    setError(false);
    setTimeout(() => {
      if (password !== passwordMock) {
        setError(true);
        inputRef.current?.focus();
      }
      setLoading(false);
    }, 1000);
  };

  return modalIsActive ? (
    <ModalContainer>
      <Content>
        <Header>
          <Title>
            <IconTitle>
              <IconLock />
            </IconTitle>
            Sala privada
          </Title>
          <CloseButtonModal onClick={closeModal}>
            <IconClose />
          </CloseButtonModal>
        </Header>
        <Body style={{ borderBottom: 'none' }}>
          <MessageInfo>
            Esta sala não está disponível para todo o público. Para entrar,
            digite a senha de acesso abaixo:
          </MessageInfo>
          <InputAnimated>
            <Input
              error={error}
              ref={inputRef}
              message={
                error
                  ? '🎉🎈 Puxa! Parece que essa não é a senha correta para entrar na sala. Vamos tentar de novo? 💪✨'
                  : ''
              }
              className={error ? 'animate' : ''}
              label="Senha"
              required
              value={passwordInput}
              onChange={e => setPasswordInput(e.target.value)}
            />
          </InputAnimated>
          <Button
            variant="primary"
            title="Entrar na sala"
            type="button"
            isLoading={loading}
            onClick={() => joinRoom(passwordInput)}
          />
        </Body>
      </Content>
    </ModalContainer>
  ) : null;
};

export default JoinRoomModal;
