import store from 'data/store';
import { Dispatch } from 'redux';

import { getAllRequest } from 'data/store/reducer/roomLite/actions/getAll';

import { iActionGetAll } from 'domain/interfaces/redux/roomLite/getAll';

import { ListRoomLite } from 'domain/usecases/roomLite/redux';

export class ReduxListRoomLite implements ListRoomLite {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: ListRoomLite.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
