import React from 'react';

import ChangeRoomModal from '../ChangeRoomModal';
import EnterRoomPolicyModal from '../EnterRoomPolicyModal/EnterRoomPolicyModal';
import InsideMapModal from '../InsideMapModal';
import InviteModal from '../InviteModal';
import LoginModal from '../LoginModal/LoginModal';
import { CreateEventSuccess, InfoEvent, Loading } from '../Modals';
import CreateRoomModal from '../Modals/CreateRoom';
import FirstTimeHere from '../Modals/FirstTimeHere';
import GuestLogin from '../Modals/GuestLogin';
import JoinRoomModal from '../Modals/JoinRoom';
import RoomsConfigureModal from '../Modals/RoomsConfigure';
import SuccessOnCreateEvent from '../Modals/SuccessOnCreateEvent';
import PolicyModal from '../PolicyModal/PolicyModal';
import { RecoveryPasswordModal } from '../RecoveryPasswordModal';
import { RegisterModal } from '../RegisterModal';
import { ownProps } from './interface';
import { ConnectComponent } from './mapper/mapperMessage';

const Notifications: React.FC<ownProps> = ({
  children,
  message,
}): JSX.Element => {
  return (
    <>
      <RegisterModal message={message} />
      <LoginModal message={message} />
      <RecoveryPasswordModal message={message} />
      <PolicyModal message={message} />
      <EnterRoomPolicyModal message={message} />
      <ChangeRoomModal message={message} />
      <InviteModal message={message} />
      <InsideMapModal message={message} />
      <CreateEventSuccess message={message} />
      <InfoEvent message={message} />
      <Loading message={message} />
      <RoomsConfigureModal message={message} />
      <SuccessOnCreateEvent message={message} />
      <FirstTimeHere message={message} />
      <GuestLogin message={message} />
      <CreateRoomModal message={message} />
      <JoinRoomModal message={message} />
      {children}
    </>
  );
};

export default ConnectComponent(Notifications);
