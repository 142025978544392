import { iStore } from 'domain/interfaces/models';
import { Room } from 'domain/interfaces/models/RoomLite';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { IconEventLocked, IconShare } from 'presentation/base/icons';
import { Button } from 'presentation/components/UI';
import { useWRTC } from 'presentation/contexts/WRTCContext';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { makeRemoteCreateRoomLite } from 'main/factories/usecases/roomLite/CreateRoomLiteFactory';
import {
  ContainerCard,
  ContainerTags,
  ContainerTitle,
  ContentCard,
  EventParticipants,
  EventTitle,
  EventsInfo,
  Footer,
  LockedDiv,
  Tag,
  Typography,
  UserIconStyled,
} from './styles/StyledCard';

interface iCard {
  event: Room;
}

const Card: React.FC<iCard> = ({ event }) => {
  const history = useHistory();

  const { joinRoom } = useWRTC();
  const { accessToken, user } = useSelector((store: iStore) => store.auth);

  const joinRoomHandler = useCallback(
    async (password: string) => {
      const joinRoomServer = makeRemoteCreateRoomLite();
      try {
        await joinRoomServer.join({
          id: event.id,
          body: {
            password,
          },
        });
        joinRoom(String(event.id), '1');
      } catch {
        console.log('error');
      }
    },
    [event, joinRoom],
  );

  const handleJoinRoom = useCallback(() => {
    const { joinPolicy } = event;

    if (joinPolicy === 'RESTRICT') {
      makeReduxActiveMessage().active({
        active: MessageOptions.joinRoomModal,
        roomId: event.id,
      });
    } else {
      joinRoomHandler('senha123');
    }
  }, [event, joinRoomHandler]);

  const handleUserLogged = useCallback(() => {
    if (!accessToken || !user) {
      makeReduxActiveMessage().active({
        active: MessageOptions.loginModal,
        actionOk: handleJoinRoom,
      });
    } else {
      handleJoinRoom();
    }
  }, [accessToken, handleJoinRoom, user]);

  if (!event?.createdAt || !event?.title) return <></>;

  const date = new Date(event.createdAt);
  const dateFormatted = Intl.DateTimeFormat('pt-BR', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(date);

  return (
    <ContainerCard>
      <ContentCard>
        <EventsInfo>
          <Typography>{dateFormatted}</Typography>
          <EventParticipants>
            <UserIconStyled />
            <Typography>0/20</Typography>
          </EventParticipants>
        </EventsInfo>
        <ContainerTitle>
          <LockedDiv isPrivate={event.joinPolicy === 'RESTRICT'}>
            <IconEventLocked />
          </LockedDiv>
          <EventTitle>{event.title}</EventTitle>
        </ContainerTitle>
        <ContainerTags>
          {event.keywords.map(keyword => (
            <Tag key={keyword}>{keyword}</Tag>
          ))}
        </ContainerTags>
        <Footer>
          <Button
            title="Entrar na sala"
            onClick={handleUserLogged}
            colorstyle="black3"
            hoverColor="white5"
            id="button_click"
          />
          <Button
            colorstyle="black3"
            hoverColor="white5"
            size="small"
            iconSize={16}
            icon={IconShare}
          />
        </Footer>
      </ContentCard>
    </ContainerCard>
  );
};

export default Card;
