import { RoomLiteTypes } from 'domain/interfaces/redux/roomLite/types';
import { takeLatest } from 'redux-saga/effects';
import { onCreate, onCreateFailed, onCreateSuccess } from './Create';
import { onGetAll, onGetAllFailed, onGetAllSuccess } from './GetAll';

const tasks = [
  takeLatest(RoomLiteTypes.CREATE, onCreate),
  takeLatest(RoomLiteTypes.CREATE_SUCCESS, onCreateSuccess),
  takeLatest(RoomLiteTypes.CREATE_FAILED, onCreateFailed),
  takeLatest(RoomLiteTypes.GETALL, onGetAll),
  takeLatest(RoomLiteTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(RoomLiteTypes.GETALL_FAILED, onGetAllFailed),
];

export default tasks;
